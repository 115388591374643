import { MegaportError } from '@megaport/api-sdk'

import { handleSessionTimeout } from '@/AuthHelpers.js'
import { promiseWithResolvers, GraphQLError } from '@/helpers'
import { handleRequest, post } from '@/CPQLiteFetcher'

const initialState = {
  // JWT returned from cpqlite-api
  token: null,
  // A Callback to allow any API requests to wait for login before submitting their request
  // Only necessary for page refresh so unused during regular login flow
  loginCallback: promiseWithResolvers(),
}

const getters = {}

const actions = {
  /**
   * Log into the CPQLite API using a Megalith JWT
   *
   * @param {string} token Megalith JWT
   * @returns CPQLite JWT
   * @throws MegaportError if authentication fails
   */
  async login({ state, commit }, token) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    try {
      const { access_token } = await post('login', config)

      commit('setToken', access_token)

      // Clear any pending API requests to proceed
      state.loginCallback.resolve()

      return access_token
    } catch (error) {
      // Cancel any pending requests
      const wrappedError = new MegaportError(error)

      state.loginCallback.reject(wrappedError)

      throw wrappedError
    }
  },

  /**
   * Helper function for making requests to CPQLite. Automatically handles refreshing an expired session.
   *
   * @param {object} request Axios config object for making the API request
   * @returns Raw Axios response object
   * @throws MegaportError if request fails (even after re-authentication for 401 errors)
   */
  async call({ state, dispatch, rootGetters }, request) {
    // Wait for the user to be logged in before proceeding
    if (state.token == null) await state.loginCallback.promise

    // Augment the request with the JWT
    const { method, url, ...config } = request

    config.headers = { ...config.headers, Authorization: `Bearer ${state.token}` }

    try {
      // First attempt the request normally
      return await handleRequest(method, url, config)
    } catch (error) {
      // If this wasn't a timeout error, then fail here (let the caller deal with the error as they see fit).
      if (error.response?.status !== 401) throw new MegaportError(error)
    }

    // Otherwise we timed out; try re-authenticating and sending the request again.
    try {
      const megalithToken = rootGetters['Auth/jwt']
      const newToken = await dispatch('login', megalithToken)

      // Update our request header to use the new token
      config.headers.Authorization = `Bearer ${newToken}`

      return await handleRequest(method, url, config)
    } catch (error) {
      const wrappedError = error instanceof MegaportError
        ? error
        : new MegaportError(error)

      // If we're still getting 401 then our Megalith token is expired; log the user out.
      if (wrappedError.status === 401) {
        handleSessionTimeout()
        wrappedError.handled = true
      }

      throw wrappedError
    }
  },

  /**
   * Helper function for submitting a GraphQL query to CPQLite
   *
   * @param {object} data GraphQL query data to be submitted. Includes the query itself and any query variables
   * @returns Data returned by the GraphQL query
   * @throws GraphQLError if the query had errors, or MegaportError if the API call failed
   */
  async query({ dispatch }, data) {
    const request = {
      method: 'POST',
      url: 'graphql',
      data,
    }

    const response = await dispatch('call', request)

    // GraphQL request itself failed (which still returns 200 from API)
    if (response.errors) {
      throw new GraphQLError(response.errors)
    }

    return response.data
  },
}

const mutations = {
  /**
   * Set the CPQLite JWT for future API requests
   * @param {string} token - the data to save
   */
  setToken(state, token) {
    state.token = token
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
