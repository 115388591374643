import sentry from '@/third-party-integrations/sentry'
import googleLogin from '@/third-party-integrations/googleLogin.js'
import awsAmplify from '@/third-party-integrations/awsAmplify.js'
import oneTrust from '@/third-party-integrations/oneTrust'
import segment from '@/third-party-integrations/segment'

const integrations = {}

export function setupIntegrations(environment) {
  // Sentry: make this the first to load so that if other integrations need to report errors, they can
  sentry.init(environment)
  integrations.sentry = sentry

  // OneTrust
  oneTrust.init()
  integrations.oneTrust = oneTrust

  // Google Login
  googleLogin.init(environment)
  integrations.googleLogin = googleLogin

  awsAmplify.init(environment)
  integrations.awsAmplify = awsAmplify

  // Segment
  segment.init(environment)
  integrations.segment = segment
}

export default integrations
