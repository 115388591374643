import { Amplify, Auth, Hub } from 'aws-amplify'

class AWSAmplify {
  init(appConfiguration) {
    const { amplify: config } = appConfiguration

    if (!config) {
      console.error('amplify configuration missing')
      return
    }

    const oauth = {
      domain: config.OAUTH_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: `${window.location.origin}/${config.SIGNIN_CALLBACK}`,
      redirectSignOut: `${window.location.origin}/${config.SIGNOUT_CALLBACK}`,
      responseType: 'code',
    }

    const awsConfig = {
      Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: config.IDENTITY_POOL_ID,
        // REQUIRED - Amazon Cognito Region
        region: config.REGION,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: config.USER_POOL_ID,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: config.CLIENT_ID,
        oauth,
      },
    }

    Amplify.configure(awsConfig)
  }

  federatedSignIn(options = { provider: 'Google' }) {
    return Auth.federatedSignIn(options)
  }

  addHubListener({ signInCallback, signInFailureCallback }) {
    Hub.listen('auth', ({ payload: { data, event } }) => {
      switch (event) {
        case 'signIn': {
          const { accessToken, idToken } = data.signInUserSession
          const payload = {
            access_token: accessToken.jwtToken,
            id_token: idToken.jwtToken,
          }
          signInCallback && signInCallback(payload)
          break
        }
        case 'signIn_failure':
          signInFailureCallback && signInFailureCallback(data)
          break
      }
    })
  }

  getUserSessionToken() {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(data => {
          resolve({
            access_token: data.accessToken.jwtToken,
            id_token: data.idToken.jwtToken,
          })
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  isUserAuthenticated() {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(user => {
          resolve(user)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  refreshSession() {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(cognitoUser => {
          const { refreshToken } = cognitoUser.getSignInUserSession()
          return cognitoUser.refreshSession(refreshToken, (err, session) => {
            if (err) {
              reject(err)
              return
            }
            resolve(session)
          })
        })
        .catch(err => reject(err))
    })
  }

  logout() {
    Auth.signOut()
      .then(() => {})
      .catch(err => {
        Promise.reject(err)
      })
  }
}

export default new AWSAmplify()
