import * as Sentry from '@sentry/vue'
import Vue from 'vue'

import git_hash from '@/gitVersion.js'
import router from '@/router'

import { MegaportError } from '@megaport/api-sdk'
import { version as appVersion } from '@/../package.json'

let disabled = false

export default {
  init(appConfig) {
    if (!appConfig.sentry || appConfig.sentry.disabled) {
      disabled = true
      return
    }

    const isDevelopmentMode = import.meta.env.DEV
    const isTestingEnv = /^(qa|uat)\d*$/i.test(appConfig.environmentName)

    Sentry.init({
      Vue,
      // We override the environment in development mode, this means when running in the dev server we are safe to use any environments
      // configuration (so environmentName: staging) and it won't send that to sentry accidentally
      environment: isDevelopmentMode ? 'development' : appConfig.environmentName,
      // Sentry is better at handing release tracking if we strictly follow semver in reported versions
      release: `QuotingTool@${appVersion}`,
      dsn: appConfig.sentry.dsn,
      trackComponents: true,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      normalizeDepth: 10, // Allow reported data to exceed the default depth of 3
      tracesSampleRate: appConfig.sentry.tracesSampleRate ?? 0,
      replaysSessionSampleRate: appConfig.sentry.replaysSessionSampleRate ?? 0,
      replaysOnErrorSampleRate: appConfig.sentry.replaysOnErrorSampleRate ?? 0,
      ignoreErrors: [
        /ResizeObserver loop/,
        // Cancelled Request Errors
        /^AbortError/,
        /^canceled$/,
        / aborted\.?$/,
        // Session Timeout Error
        /^Bad session token - try logging in again\.$/,
        // Vue Router Warnings
        /^Avoided redundant navigation to current location: "[^"]+"\.$/,
        /^Navigation cancelled from "[^"]+" to "[^"]+" with a new navigation\.$/,
        /^Redirected when going from "[^"]+" to "[^"]+" via a navigation guard\.$/,
        // Google Auth Internal Errors
        /^[A-Z]$/,
        // Permissions Error
        /is not allowed by the user agent or the platform in the current context, possibly because the user denied permission\.$/,
      ],
      initialScope: {
        tags: { git_hash },
      },
      beforeSend(event, hint) {
        // If an error happens in a uat or qa environment, alert the user with a non-closing message.
        if (isDevelopmentMode || isTestingEnv) {
          console.log('Sentry...', event) // eslint-disable-line no-console
          if (hint.originalException) {
            console.error(hint.originalException)
            if (hint.originalException.cause) console.error(hint.originalException.cause)
          }

          // Only allow one pop-up at a time since it doesn't automatically close
          window.mpApp.$notify.error({
            title: 'Sentry Error',
            message: 'Please see console for details',
            position: 'bottom-left',
          })
        }
        // Don't send messages in development environment

        if (isDevelopmentMode) return null
        return event
      },
    })
  },

  setUser(userId, accountType) {
    if (disabled) return

    Sentry.setUser({
      id: userId,
      accountType,
    })
  },

  captureException(e) {
    if (disabled) return
    if (e.handled || e instanceof MegaportError) return

    Sentry.captureException(e)
  },
}
