// For the purposes of the CPQ project, this will contain any strings that are not included in the base translations
// that we imported from the portal. No need to translate anything at this stage, but should we ever need to add
// other languages, this will be the set of things that will require translating to get up to speed.
export default {
  'app-info': {
    name: `${import.meta.env.VITE_APP_INFO_NAME}`,
    'name-short': `${import.meta.env.VITE_APP_INFO_NAME_SHORT}`,
  },
  productNames: {
    mcrMedium: `${import.meta.env.VITE_PRODUCTNAME_MCR_MEDIUM}`,
    mveMedium: `${import.meta.env.VITE_PRODUCTNAME_MVE_MEDIUM}`,
    megaportInternet: `${import.meta.env.VITE_PRODUCTNAME_TRANSIT_VXC}`,
  },
  authentication: {
    'password-must-include': `Your password must`,
    'at-least-8-char': `Be at least 8 characters`,
    'at-least-one-number': `Have at least one number`,
    'at-least-one-symbol': `Have at least one symbol`,
    'at-least-one-lower-case-letter': `Have at least one lower case letter`,
    'at-least-one-upper-case-letter': `Have at least one upper case letter`,
    'confirm-new-password': `Confirm New Password`,
    'mfa-token-invalid': `One time code {code} is invalid. After 5 consecutive unsuccessful attempts, your account will be locked.`,
    'mfa-reset-customer-portal': `Your MFA has been reset, please sign in through the <a href="{portalUrl}">customer portal</a> to reset MFA before trying to login again`,
    'mfa-enforced-customer-portal': `MFA is now enforced, please sign in through the <a href="{portalUrl}">customer portal</a> to setup MFA before trying to login again`,
    'mfa-token-required': `One time multi-factor authentication token required. The form has been updated with the required field.`,
    'mfa-token': `Multi-Factor Authentication Token`,
    'mfa-long': `Multi-Factor Authentication`,
    'mfa-enter-token': `MFA is enabled for your account. Please enter a token to log in.`,
    verify: `Verify`,
    'mfa-verify-token': `Verifying token`,
    'mfa-instructions': `Scan the image above with the multi-factor authentication app on your phone. If you can’t use a barcode, enter | this text code | in the app instead.`,
    'no-access': `Your account does not have access to ${import.meta.env.VITE_APP_INFO_NAME}.`,
    'managed-account-no-access': `Managed accounts do not have access to ${import.meta.env.VITE_APP_INFO_NAME}. Please contact your ${import.meta.env.VITE_COMPANYINFO_NAME} Partner for estimating service cost.`,
    'sso-enforced-no-access': `A Company Administrator has enforced Single Sign-On and you must only login via SSO. Please contact your Company Administrator if you need assistance with SSO access.`,
    'login-sso-help-reset-password': `If you are having issues logging in with your email and password, try resetting your password using the "Forgot Password" link below.`,
    'login-sso-help-idp-config': `If you have Single Sign-On (SSO) enabled but can't log in, there might be an IdP configuration error or your IdP is not responding. You might be able to log in directly using your ${import.meta.env.VITE_COMPANYINFO_NAME} username and password if you are a Company Admin or if SSO is set to Optional.`,
    'get-help-signing-in': `Get help signing in`,
    'log-out': `Log Out`,
    'toggle-password-visibility': `Toggle password visibility`,
    'password-reset': `Your account requires a password reset. Set a new password here to log in.`,
    'new-password': `New Password`,
  },
  general: {
    'confirm-remove-type': `Are you sure you want to remove this {type}?`,
    change: `Change`,
    'please-select': `Please select`,
    'no-matching-rows': `No matching rows`,
    'copy-with-styling': `Copy With Styling`,
    'copy-plain': `Copy Plain Table`,
    updated: `{thing} updated.`,
    'send-feedback': `Send feedback`,
    'report-bug': `Report Bug`,
    'please-confirm': `Please Confirm`,
    total: `Total`,
    enable: `Enable`,
    'network-design': `Network Design`,
    'close-editor': `Please save/close the editor before adding a new service`,
    close: `Close`,
    duplicate: `Duplicate`,
    customer: `Customer`,
    'deal-id': `Deal ID`,
    date: `Date`,
    'created-date': `Created Date`,
    'copied-to-clipboard': `{target} copied to clipboard.`,
    'copy-failed': `Failed to copy to clipboard.`,
    'data-centre-logo': `Data centre logo`,
    'vendor-logo': `Vendor logo`,
    currency: `Currency`,
    'select-currency': `Select Currency`,
    'legal-notice': `Legal notice`,
    retry: `Retry`,
    'unexpected-error': `An unexpected error has occurred. Try again later. If this error persists contact ${import.meta.env.VITE_COMPANYINFO_SUPPORT_EMAIL}`,
    'start-over': `Start Over`,
    'existing-customer': `Existing Customer`,
    'potential-customer': `Potential Customer`,
    id: `ID`,
    'copy-full-uuid': `Copy Full UUID`,
    'partner-name': `Partner Name`,
    'managed-company': `Managed Company`,
    'managed-company-details': `Managed Company Details`,
    'customer-account': `Customer Account`,
    'customer-company': `Customer Company`,
    'customer-company-details': `Customer Company Details`,
    'customer-name': `Customer Name`,
    subscription: `Subscription`,
    'select-subscription': `Select Subscription`,
    'monthly-subscription': `Month to Month`,
    unsaved: `Unsaved Changes`,
    'megaport-logo': `${import.meta.env.VITE_COMPANYINFO_NAME} Logo`,
    'company-logo': `{company} Logo`,
    'configure-price-quote': `Configure · Price · Quote`,
    'cloud-service-provider': `Cloud Service Provider`,
    download: `Download`,
    metadata: `Metadata`,
    'show-more-actions': `Show More Actions`,
  },
  connections: {
    'vxc-between': `between {start} and {end}`,
    remove: `Remove Connection`,
    new: `New Connection`,
    'confirm-remove': `Are you sure you want to remove this connection?`,
    editor: `{type} Editor`,
    'private-vxc-editor': `Private ${import.meta.env.VITE_PRODUCTNAME_VXC} Editor`,
    'marketplace-vxc-editor': `${import.meta.env.VITE_PRODUCTNAME_MARKETPLACE_SHORT} ${import.meta.env.VITE_PRODUCTNAME_VXC} Editor`,
    'cloud-vxc-editor': `Cloud ${import.meta.env.VITE_PRODUCTNAME_VXC} Editor`,
    cloud: `Cloud`,
    private: `Private`,
    marketplace: `${import.meta.env.VITE_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'internet-exchange': `Internet Exchange`,
    'destination-ix': `Destination ${import.meta.env.VITE_PRODUCTNAME_IX}`,
    'destination-ix-location': `Destination ${import.meta.env.VITE_PRODUCTNAME_IX} Location`,
    'choose-ix-location': `Choose ${import.meta.env.VITE_PRODUCTNAME_IX} Location`,
    'choose-cloud-provider': `Choose Cloud Provider`,
    'choose-marketplace-provider': `Choose ${import.meta.env.VITE_PRODUCTNAME_MARKETPLACE_SHORT} Provider`,
    'marketplace-provider': `${import.meta.env.VITE_PRODUCTNAME_MARKETPLACE_SHORT} Provider`,
    'destination-location': `Destination Location`,
    'port-location': `${import.meta.env.VITE_PRODUCTNAME_PORT} Location`,
    'choose-location': `Choose Location`,
    'select-location-first': `Please select location first`,
    'transit-default-name': `{aEnd} to ${import.meta.env.VITE_PRODUCTNAME_TRANSIT_VXC}`,
    'locations-count': `{count} locations`,
    'locations-type-count': `0 {type} locations | 1 {type} location | {count} {type} locations`,
    'no-megaport-internet': `No ${import.meta.env.VITE_PRODUCTNAME_TRANSIT_VXC} available at this location`,
    'transit-vxc-deleted-no-target': `${import.meta.env.VITE_PRODUCTNAME_TRANSIT_VXC} ${import.meta.env.VITE_PRODUCTNAME_VXC} of name {connectionName} was deleted as a suitable ${import.meta.env.VITE_PRODUCTNAME_TRANSIT_VXC} destination in the new market was not found.`,
    'transit-vxc-deleted-market-not-enabled': `${import.meta.env.VITE_PRODUCTNAME_TRANSIT_VXC} ${import.meta.env.VITE_PRODUCTNAME_VXC} of name {connectionName} was deleted as the new location is not in a ${import.meta.env.VITE_PRODUCTNAME_TRANSIT_VXC} enabled market.`,
    edit: `Edit Connection`,
  },
  pricebook: {
    subtotal: `Subtotal`,
    disclaimer: `This quote is non-binding and subject to change. It is provided for information only and is not an offer capable of acceptance. Taxes are excluded.`,
  },
  services: {
    'product-size': `Product Size`,
    'product-type-name': `{type} Name`,
    'port-type-name': `{type} Name`,
    'rate-limit-warning': `Note: Rate limit cannot be modified for this type of service after deployment.`,
    'monthly-price': `Monthly Price`,
    'no-new-defined': `No new services defined yet`,
    'select-port-speed': `Select ${import.meta.env.VITE_PRODUCTNAME_PORT} Speed`,
    'ratelimit-request-fail': `Unable to retrieve rate limits for this location, please try again.`,
    'transit-vxc-location-warning': `Note: The current service has one or more associated ${import.meta.env.VITE_PRODUCTNAME_TRANSIT_VXC} ${import.meta.env.VITE_PRODUCTNAME_VXCS}. Changing the location may result in the deletion of these ${import.meta.env.VITE_PRODUCTNAME_VXCS} if a suitable match is not found for the new location.`,
    edit: `Edit Service`,
    remove: `Remove Service`,
  },
  ports: {
    'link-aggregation-group': `${import.meta.env.VITE_PRODUCTNAME_LAG_LONG}`,
    'mve-location': `${import.meta.env.VITE_PRODUCTNAME_MVE} Location`,
    'create-lacp-info': `Creates this ${import.meta.env.VITE_PRODUCTNAME_PORT} with the LACP protocol enabled so it can be used on a ${import.meta.env.VITE_PRODUCTNAME_LAG_LONG} (${import.meta.env.VITE_PRODUCTNAME_LAG}) and allows you to specify the number of ports to include in your ${import.meta.env.VITE_PRODUCTNAME_LAG}.`,
    'ports-to-lag-info': `This will add multiple ${import.meta.env.VITE_PRODUCTNAME_PORTS} on this ${import.meta.env.VITE_PRODUCTNAME_LAG_LONG} (${import.meta.env.VITE_PRODUCTNAME_LAG}). Pricing displayed is for all the ports being added to the ${import.meta.env.VITE_PRODUCTNAME_LAG}.`,
    'lag-disclaimer': `Note: when adding ${import.meta.env.VITE_PRODUCTNAME_PORTS} to a live ${import.meta.env.VITE_PRODUCTNAME_LAG}, the new ${import.meta.env.VITE_PRODUCTNAME_PORT} or ${import.meta.env.VITE_PRODUCTNAME_PORTS} are automatically assigned the same location and speed as the rest of the ${import.meta.env.VITE_PRODUCTNAME_LAG}.`,
    'number-lag': `Number of ${import.meta.env.VITE_PRODUCTNAME_LAG} ${import.meta.env.VITE_PRODUCTNAME_PORTS}`,
  },
  validations: {
    'mfa-token': `Enter your MFA token`,
    'greater-than-one': `Please enter a value greater than or equal to 1`,
    'lag-speeds': `No ${import.meta.env.VITE_PRODUCTNAME_PORT} speeds < 10Gbps in ${import.meta.env.VITE_PRODUCTNAME_LAG}`,
    'please-correct-issues': `Please correct the indicated issues.`,
    'invalid-type': `{field} must be a valid {type}`,
  },
  'partner-vantage': {
    'save-deal-id': `Save Deal ID`,
    'deal-id': `Deal ID`,
    'deal-summary': `Deal Summary`,
    'copy-summary': `Copy Summary`,
    'send-to-register': `Send to Register`,
    'send-to-register-summary': `When you register the quote in VantageHub a Deal ID will be provided once the deal is approved. Please enter the Deal ID to complete the quote.`,
    'deal-quote-service-required': `Deal quote must contain at least one service.`,
    'already-registered': `Quote has already been registered, generate a new quote to register against a deal.`,
    'register-failed': `Failed to register your Deal ID`,
    'deal-summary-line': `{amount} / Monthly`,
    'redundant-action': `Redundant Action`,
    'empty-quote': `Empty Quote`,
  },
  quote: {
    'create-new': `Create New Quote`,
    name: `Quote Name`,
    'default-name': `{company} Quote`,
    save: `Save Quote`,
    saved: `Quote Saved`,
    id: `Quote ID`,
    'loading-error': `Error Loading Quote`,
    'loading-error-long': `Failed to load quote with ID: {quoteId}`,
    'save-and-download': `Save and Download`,
    details: `Quote Details`,
    'downloading-csv-error': `Error Downloading CSV`,
    'downloading-csv-error-long': `Failed to download the CSV version of your quote`,
    'dirty-exit': `Your configuration has unsaved changes. Would you like to save them before you exit?`,
    'confirm-delete': `Are you sure you want to delete this quote?`,
    'deleting-error': `Error Deleting Quote`,
    'deleting-error-long': `Failed to delete quote with ID: {quoteId}`,
    'quote-deleted': `Quote Deleted`,
    'quote-deleted-long': `Quote with ID: {quoteId} has been successfully deleted`,
    'duplicating-error': `Error Duplicating Quote`,
    'duplicating-error-long': `Failed to duplicate quote with ID: {quoteId}`,
    'quote-duplicated': `Quote Duplicated`,
    'quote-duplicated-long': `Quote with ID: {quoteId} has been successfully duplicated`,
    builder: `Quote Builder`,
    provider: `Quote Provider`,
  },
  quotes: {
    saved: `Saved Quotes`,
    'short-description': `View a list of your quotes. Here you can create, save and forward new quotes. Quotes will be deleted after 6 months.`,
    'loading-error': `Error Loading Quotes`,
    'loading-error-long': `Failed to load saved quotes`,
    search: `Search Quotes`,
  },
  print: {
    'print-pdf': `Print PDF`,
    'registered-date': `Registered Date`,
    'network-config-heading': `Network Design Configuration`,
    'column-service-type': `Service Type`,
    'column-service-configuration': `Service Configuration`,
    'column-service-term': `Service Term`,
    connections: `Connections`,
  },
  'new-quote': {
    'target-type-help-text': `Please select the type of {target} you are creating a quote for.`,
    'target-type-customer': `customer`,
    'target-type-account': `account`,
    'company-uid-help-text': `Please search for your target company by its UID`,
    'company-name-help-text': `You can provide a company name for future reference and added personalisation`,
    'company-name-help-text-check': `Please check the company name is correct to ensure accurate pricing`,
    'auto-company-name-help-text': `This field is read-only and will be automatically populated`,
    'company-fetch-error': `Failed to fetch company details`,
    'company-fetch-success': `Company details have been successfully retrieved`,
  },
  partner: {
    'existing-managed-account': `Existing Managed Account`,
    'new-managed-account': `New Managed Account`,
    'error-loading-managed-accounts': `Error loading managed accounts`,
    'select-account': `Select Account`,
    'filter-account': `Filter accounts by name or reference`,
    'no-matching-accounts': `No matching accounts`,
  },
  menu: {
    'user-menu': `User Menu`,
  },
}
