// External tools
import sdk from '@megaport/api-sdk'
// Internal tools
import i18n from '@/i18n/SetupI18n'
import { updateRuntimeFeatureFlag } from '@/providers/runtime.js'
// Integrations
import integrations from '@/third-party-integrations/integrations.js'
// Globals
import {
  ACCOUNT_TYPE_DIRECT_CUSTOMER,
  ACCOUNT_TYPE_MANAGED_ACCOUNT,
  ACCOUNT_TYPE_PARTNER,
  MEGAPORT_ID,
  MEGAPORT_LAB_ID,
  PORTAL_URL,
} from '@/Globals.js'

const LOGGED_OUT = 0
const LOGGING_IN = 1
const LOGGED_IN = 2

// Initial state
const initialState = {
  loginState: LOGGED_OUT,
  googleSignInReady: false,
  // This is the cached data from the login payload before it succeeds.
  userLoginPayload: {},
  // User data once logged in is stored here
  data: {},
}

const getters = {
  isLoggingIn: state => state.loginState === LOGGING_IN,

  isLoggedIn: state => state.loginState === LOGGED_IN,

  jwt: state => state.data.oAuthToken?.accessToken,

  isDirectAccount: state => state.data.accountType === ACCOUNT_TYPE_DIRECT_CUSTOMER,

  isMegaportAdminOrLabAccount: state => [MEGAPORT_ID, MEGAPORT_LAB_ID].includes(state.data.companyId),

  isPartnerAccount: state => state.data.accountType === ACCOUNT_TYPE_PARTNER,

  isNonMpDirectAccount: (_state, authGetters) => authGetters.isDirectAccount && !authGetters.isMegaportAdminOrLabAccount,
}

// Helper methods
const updateRuntime = data => {
  // Update the runtime configs from the response
  const [first, second] = ['companyConfiguration', 'consolidatedSettings']
  const consolidatedSettings = data?.[first]?.[second]
  if (consolidatedSettings) {
    consolidatedSettings.forEach(({ key, value }) => updateRuntimeFeatureFlag([first, second, key], value))
  }
}

const processSuccessfulLogin = async (context, data) => {
  // Ensure a clean slate in case of cached login data
  context.commit('setUserLoginPayload', {})

  // Save the user data locally
  context.commit('login', data)

  await context.dispatch('onLogin', null, { root: true })

  // Record that we are logged in
  context.commit('setLoginState', LOGGED_IN)
  integrations.sentry.setUser(data.personUid, data.accountType)

  // Update Segment with the user data
  integrations.segment.setUser(data)
  integrations.segment.trackEvent('login')

  // Display the successful login message
  context.commit(
    'Notifications/notifyGood',
    { message: i18n.t('authentication.login-success-message') },
    { root: true }
  )
}

// Asynchronous actions
const actions = {
  /**
   * This is the single place login is handled.
   *
   * @param {object} context (store context)
   * @param {object} payload - data used to authenticate (see megaport.js and api)
   * @param {Boolean} setLoginState - whether to set the login state locally
   */
  async login(context, { payload, setLoginState = true }) {
    context.commit('setLoginState', LOGGING_IN)

    try {
      const loginObj = await sdk.instance.auth(payload)

      // Make sure that they are allowed to log in.
      updateRuntime(loginObj)

      const isManagedAccount = loginObj.accountType === ACCOUNT_TYPE_MANAGED_ACCOUNT

      // Managed accounts are not allowed to log in
      if (isManagedAccount) {
        throw {
          data: {
            message: i18n.t('authentication.managed-account-no-access'),
          },
        }
      }

      const isMegaportAdminOrLabAccount = [MEGAPORT_ID, MEGAPORT_LAB_ID].includes(loginObj.companyId)
      const isDirectOrPartnerAccount = [ACCOUNT_TYPE_DIRECT_CUSTOMER, ACCOUNT_TYPE_PARTNER].includes(loginObj.accountType)

      // Only Megaport Admins, Lab accounts, Direct Customers, and Partners are allowed to log in
      if (!isMegaportAdminOrLabAccount && !isDirectOrPartnerAccount) {
        throw {
          data: {
            message: i18n.t('authentication.no-access'),
          },
        }
      }

      // MFA reset is required
      if (loginObj.mfaReset) {
        throw {
          data: {
            message: i18n.t('authentication.mfa-reset-customer-portal', { portalUrl: PORTAL_URL }),
            errorLevel: 'warning',
            isHtml: true,
          },
        }
      }

      // MFA is enforced but not enabled
      if (loginObj.companyEnforcedMFA && !loginObj.mfaEnabled) {
        throw {
          data: {
            message: i18n.t('authentication.mfa-enforced-customer-portal', { portalUrl: PORTAL_URL }),
            errorLevel: 'warning',
            isHtml: true,
          },
        }
      }

      if (setLoginState) {
        // Also log into CPQLite
        await context.dispatch('CPQLite/login', loginObj.oAuthToken?.accessToken, { root: true })
        // Handle successful login side effects
        await processSuccessfulLogin(context, loginObj)
      } else {
        context.commit('setUserLoginPayload', payload)
      }

      return loginObj
    } catch (error) {
      context.commit('setUserLoginPayload', payload)
      context.commit('setLoginState', LOGGED_OUT)

      throw error
    }
  },

  /**
   * This is the single place to log out. It cleans up the megaport.js layer
   * and calls the parent action which causes all the individual modules to
   * clean up their state. It then redirects to the login screen.
   */
  async logout({ dispatch }) {
    try {
      await sdk.instance.logout()
      // Tell Segment that the user has logged out
      integrations.segment.clearUser()
    } catch (error) {
      // Intentionally empty, we don't care if the actual logout request fails.
    } finally {
      dispatch('onLogout', null, { root: true })
    }
  },
}

// Direct mutations
const mutations = {
  /**
   * PRIVATE method to set the state based on the object passed in.
   *
   * @param {*} state - module state
   * @param {*} payload - the data to save
   */
  login(state, payload) {
    if (!payload.accountManager) {
      payload.accountManager = { name: null, email: null }
    }

    state.data = { ...payload }

    state.loggedIn = true

    if (payload.oAuthToken?.accessToken) {
      localStorage.setItem('_accessToken', payload.oAuthToken.accessToken)
    }
  },

  /**
   * PRIVATE method to update the state and clean up from a logout operation
   * @param {*} state - module state
   */
  logout(state) {
    localStorage.removeItem('_accessToken')
    // Wipe out our auth state and record that we are not logged in
    state.data = {}
    state.loggedIn = false
    state.loginState = LOGGED_OUT
  },

  /**
   * PRIVATE method to update the logged in status.
   *
   * @param {object} state
   * @param {Number} status Must be one of LOGGED_OUT, LOGGING_IN, or LOGGED_IN
   */
  setLoginState(state, status) {
    state.loginState = status

    if (status === LOGGED_OUT) {
      localStorage.removeItem('_accessToken')
    }
  },

  setGoogleSignInReady(state, tf) {
    state.googleSignInReady = tf
  },

  setLoggingIn(state) {
    state.loginState = LOGGING_IN
  },

  setLoggedOut(state) {
    state.loginState = LOGGED_OUT
  },

  setUserLoginPayload(state, payload) {
    state.userLoginPayload = payload
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
