import Vue from 'vue'
import Vuex from 'vuex'

import storeConfig from './config.js'

Vue.use(Vuex)

const store = new Vuex.Store(storeConfig)

// Configure for hot module reloading
if (import.meta.hot) {
  // accept actions and mutations as hot modules
  import.meta.hot.accept(
    [
      './RootActions.js',
      './modules/Auth.js',
      './modules/CPQLite.js',
      './modules/Quotes.js',
      './modules/Mve.js',
      './modules/IxTypes.js',
      './modules/Notifications.js',
      './modules/CSPs.js',
      './modules/Locations.js',
      './modules/ConfiguredServices.js',
      './modules/TransitPorts.js',
      './modules/Partner.js',
    ],
    () => {
      // require the updated modules and swap in the new actions and mutations
      console.info('Hot updating store modules')
      store.hotUpdate({
        actions: require('./RootActions.js'),
        modules: {
          Auth: require('./modules/Auth.js').default,
          CPQLite: require('./modules/CPQLite.js').default,
          Quotes: require('./modules/Quotes.js').default,
          Mve: require('./modules/Mve.js').default,
          IxTypes: require('./modules/IxTypes.js').default,
          Notifications: require('./modules/Notifications.js').default,
          CSPs: require('./modules/CSPs.js').default,
          Locations: require('./modules/Locations.js').default,
          ConfiguredServices: require('./modules/ConfiguredServices.js').default,
          TransitPorts: require('./modules/TransitPorts.js').default,
          Partner: require('./modules/Partner.js').default,
        },
      })
    }
  )
}

export default store
