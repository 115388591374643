import passwordStrength from 'zxcvbn'

export const showModalAlert = (message, title, type, isHtml = false) => {
  let confirmButtonClass
  switch (type) {
    case 'success':
      confirmButtonClass = 'el-button--success'
      break
    case 'info':
      confirmButtonClass = 'el-button--info'
      break
    case 'warning':
      confirmButtonClass = 'el-button--warning'
      break
    case 'error':
      confirmButtonClass = 'el-button--danger'
      break

    default:
      console.error('Invalid alert type')
      return
  }

  const config = {
    confirmButtonText: window.mpApp.$t('general.ok'),
    confirmButtonClass: confirmButtonClass,
    type: type,
    customClass: `${type}-alert`,
    modal: false,
    dangerouslyUseHTMLString: isHtml,
  }

  window.mpApp.$alert(message, title, config)
    .then(() => {
      // empty function is intentional
    })
    .catch(() => {
      // empty function is intentional
    })
}


export const passStrength = (password, userInputs) => {
  const passwordAnalysis = passwordStrength(password, userInputs)
  const ratings = [
    window.mpApp.$t('authentication.very-weak'),
    window.mpApp.$t('authentication.weak'),
    window.mpApp.$t('authentication.moderate'),
    window.mpApp.$t('authentication.good'),
    window.mpApp.$t('authentication.excellent'),
  ]
  const classes = ['color-danger', 'color-danger', 'color-warning', 'color-success', 'color-success']

  // Note: for internationalization of the warnings and suggestions, we would need to have translated all the
  // returned strings in https://github.com/dropbox/zxcvbn/blob/master/src/feedback.coffee
  return {
    rating: password.length ? ratings[passwordAnalysis.score] : '',
    class: classes[passwordAnalysis.score],
    feedback: passwordAnalysis.feedback,
    score: passwordAnalysis.score,
    sequence: passwordAnalysis.sequence,
  }
}

export const handleSessionTimeout = () => {
  window.mpApp.$store.dispatch('Auth/logout')
  window.mpApp.$notify({
    title: window.mpApp.$t('authentication.failed'),
    message: window.mpApp.$t('authentication.login-failed-token'),
    type: 'error',
    duration: 10000,
  })
  window.mpApp.$router.push('/login', () => { })
}
