import { GET_QUOTES } from '@/queries.js'

const initialState = {}

const getters = {}

const actions = {
  /**
   * Execute the GET_QUOTES GraphQL query
   * @param {object} variables The variables to control the query pagination and filtering
   * @returns The query results
   */
  async getQuotes({ dispatch }, variables) {
    const data = {
      query: GET_QUOTES,
      variables,
    }

    const { projects } = await dispatch('CPQLite/query', data, { root: true })

    return projects
  },

  /**
   * Deletes a quote from CPQLite
   * @param {string} id UUID of the quote to be deleted
   */
  async deleteQuote({ dispatch }, id) {
    const request = {
      method: 'DELETE',
      url: `projects/${id}`,
    }

    await dispatch('CPQLite/call', request, { root: true })
  },

  /**
   * Downloads a CSV version of the given quote, returning it in blob format
   * @param {string} id UUID of the quote to be downloaded
   * @returns a Blob object containing the CSV data
   */
  async downloadQuoteCsv({ dispatch }, id) {
    const request = {
      method: 'GET',
      url: `projects/${id}`,
      headers: { 'Content-Type': 'text/csv; charset=UTF-8' },
      responseType: 'text',
    }

    const blobParts = await dispatch('CPQLite/call', request, { root: true })

    return new Blob([blobParts])
  },
}

const mutations = {}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
