// Strip out unnecessary whitespace from the queries before sending
const gql = query => query.toString().replaceAll(/\n\s*/g, ' ').trim()

export const GET_QUOTES = gql`
query($filter: ProjectFilter, $pagination: ProjectPage) {
  projects(filter: $filter, page: $pagination) {
    items {
      id
      targetType
      name
      managedCompany
      companyUid
      projectState
      createdBy
      createDate
      deal {
        id
      }
    }
    pageInfo {
      totalItems
    }
  }
}`

export const GET_QUOTE_DETAILS = gql`
query($id:ID) {
  project(id:$id) {
    id
    targetType
    name
    partner
    managedCompany
    companyUid
    serviceQuotes {
      name
      location
      term
      currency
      __typename
      price {
        monthlyRate
      }
      ...on Lag {
        rateLimit
        portCount
      }
      ...on Mcr2{
        rateLimit
      }
      ...on Megaport {
        rateLimit
      }
      ...on Mve {
        size
        label
        vendor
      }
      connectionQuotes {
        name
        speed
        currency
        __typename
        price {
          monthlyRate
        }
        ...on Ix {
          ixType
        }
        ...on Vxc {
          bEndLocation
          connectType
          term
        }
      }
    }
    projectState
    createdBy
    createDate
    deal {
      id
      submittedDate
    }
  }
}`
