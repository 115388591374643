const showMessage = (payload, type, vm) => {
  setTimeout(() => {
    vm.$message({
      message: payload.message,
      type,
      duration: 'duration' in payload ? payload.duration : 3000,
      showClose: payload.showClose || false,
    })
  }, 5)
}

const actions = {}

const mutations = {
  notifyGood(state, payload) {
    showMessage(payload, 'success', this._vm)
  },
  notifyWarn(state, payload) {
    showMessage(payload, 'warning', this._vm)
  },
  notifyBad(state, payload) {
    showMessage(payload, 'error', this._vm)
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
}
