import Vue from 'vue'
import { cloneDeep } from 'lodash'
import { DateTime } from 'luxon'
import { v1 as uuid } from 'uuid'
import sdk from '@megaport/api-sdk'

import router from '@/router.js'
import i18n from '@/i18n/SetupI18n'
import { GET_QUOTE_DETAILS } from '@/queries.js'
import { pricebook } from '@/helpers.js'
import {
  TARGET_TYPES,
  G_PRODUCT_TYPE_VXC,
  G_PRODUCT_TYPE_IX,
  G_PRODUCT_TYPE_MVE,
  G_PRODUCT_TYPE_MCR2,
  G_PRODUCT_TYPE_MEGAPORT,
  G_PRODUCT_TYPE_LAG,
  G_QUOTE_STATUS_REGISTERED,
  G_QUOTE_STATUS_SAVED,
} from '@/Globals.js'

/**
 * Utility method to convert from our internal representation to one that is ready to be saved to
 * the API.
 *
 * @param {object} state
 * @returns
 */
const assembleSavePayloadFromState = state => {
  const { targetType, name, partner, managedCompany, companyUid } = state.quoteConfig

  // Assemble the service quotes in the required format.
  const serviceQuotes = cloneDeep(state.serviceQuotes)

  for (const portLike of serviceQuotes) {
    // Since the API is currently slightly different to our internal representation, we need to make some alterations.
    delete portLike.id

    for (const connection of portLike.connectionQuotes) {
      delete connection.id
    }
  }

  return {
    targetType,
    name,
    partner,
    managedCompany,
    companyUid,
    serviceQuotes,
  }
}

const initialState = {
  loading: false,
  serviceQuotes: [],
  quoteConfig: {
    // User Entered Data
    targetType: '', // The type of customer/account the quote is for (EXISTING - NEW - SELF)
    name: '', // Quote name
    managedCompany: '', // Name of the managed company
    companyUid: '', // The UUID of the company the quote is for
    // API Retrieved Data
    partner: '', // Company name of the logged in user when creating, or from the API when retrieving
    id: '', // Will be populated with 32 bit id string when saved
    createdBy: '', // Name of the logged in user when creating, or from the API when retrieving
    createDate: '', // YYYY-MM-ddTHH:mm from the API after saving
    projectState: '', // Will be SAVED or REGISTERED once it's been sent to the API
    deal: {
      id: '', // Deal ID from Zift, once registered
    },
  },
  dirty: false,
  // So we can tell that the quote was loaded from the API and not try to display it
  loadedFromApi: false,
  fetchingPrices: false,
  priceAbortController: new AbortController(),
}

const getters = {
  /**
   * Get the list of the configured services along with their extra derived properties.
   *
   * @param {object} state
   * @param {object} getters
   * @param {object} rootState
   * @returns
   */
  enhancedServices(state, _gettersArg, rootState) {
    const services = cloneDeep(state.serviceQuotes)

    for (const service of services) {
      // Calculate the aggregated speed of the service or LAG
      service._aggSpeed = service.serviceType === G_PRODUCT_TYPE_LAG
        ? service.rateLimit * service.portCount
        : service.rateLimit

      // Look up the location information
      const location = rootState.Locations.locations.find(({ id }) => id === service.location)

      if (location) {
        service._location = location
      }

      // Enhance the connection object while calculating allocation percentage
      let allocated = 0

      for (const connection of service.connectionQuotes) {
        allocated += connection.speed

        connection.aEnd = {
          id: service.id,
          location: service.location,
          serviceType: service.serviceType,
        }
      }

      // Work out the percentage allocated
      service._capacity = {
        allocated,
        percent: Math.round((allocated / service._aggSpeed) * 100),
      }

      // The CSPs have the title and we are using it for the item selector
      service.title = service.name
    }

    return services
  },

  perCurrencyQuoteSummaries(_state, gettersArg) {
    const currencyMap = new Map()

    for (const service of gettersArg.enhancedServices) {
      const currency = service.currency ?? 'USD'

      // Get our existing currency group, or setup a default one if it doesn't exist yet.
      let currencyGroup = currencyMap.get(currency)

      if (!currencyGroup) {
        currencyGroup = { currency, serviceQuotes: [], total: 0 }

        currencyMap.set(currency, currencyGroup)
      }

      // Calculate the subtotal
      const subtotal = service.connectionQuotes.reduce(
        (total, connection) => total + connection.price.monthlyRate,
        service.price.monthlyRate * (service.portCount ?? 1)
      )

      // Add the quote to the currency group
      currencyGroup.serviceQuotes.push({ ...service, subtotal })

      // Increment the total
      currencyGroup.total += subtotal
    }

    return [...currencyMap.values()].sort((a, b) => a.currency.localeCompare(b.currency))
  },

  formattedCreateDate(state) {
    const dateFormat = 'LLLL d y HH:mm'
    if (state.quoteConfig.createDate) {
      const date = DateTime.fromISO(state.quoteConfig.createDate)
      if (date.isValid) {
        return date.toFormat(dateFormat)
      }
    }
    const date = DateTime.utc()
    return date.toFormat(dateFormat)
  },

  isForSelf(state) {
    return state.quoteConfig.targetType === TARGET_TYPES.SELF
  },

  isForExistingCompany(state) {
    return state.quoteConfig.targetType === TARGET_TYPES.EXISTING_COMPANY
  },

  isForNewCompany(state) {
    return state.quoteConfig.targetType === TARGET_TYPES.NEW_COMPANY
  },
}

const actions = {
  /**
   * Registers a deal against the current quote
   *
   * @param {String} id The ID of the deal
   * @returns
   */
  async sendToRegister({ state, commit, dispatch }, id) {
    const request = {
      method: 'PUT',
      url: `projects/${state.quoteConfig.id}/register`,
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
      data: { id },
    }

    await dispatch('CPQLite/call', request, { root: true })

    commit('updateQuoteConfig', { key: 'deal', value: { id } })
    commit('updateQuoteConfig', { key: 'projectState', value: G_QUOTE_STATUS_REGISTERED })
    commit('setDirty', false)
  },

  /**
   * Save the current quote information. Assume it's a new quote if it doesn't have a quote ID.
   *
   * @param {object} context
   */
  async saveQuote({ state, commit, dispatch }, withinQuoteBuilder = true) {
    const request = {
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
      data: assembleSavePayloadFromState(state),
    }

    // Update existing quote
    if (state.quoteConfig.id) {
      request.method = 'PUT'
      request.url = `projects/${state.quoteConfig.id}`

      await dispatch('CPQLite/call', request, { root: true })
    } else {
      // Create new quote
      request.method = 'POST'
      request.url = 'projects'

      const { id, deal } = await dispatch('CPQLite/call', request, { root: true })

      commit('updateQuoteConfig', { key: 'id', value: id })
      commit('updateQuoteConfig', { key: 'deal', value: deal })

      // Update the path's params
      if (withinQuoteBuilder) {
        router.replace({ params: { quoteId: id } })
      } else {
        // Navigate to Quote Builder
        router.push(`/quote-builder/${id}`)
      }
    }

    commit('updateQuoteConfig', { key: 'projectState', value: G_QUOTE_STATUS_SAVED })
    commit('setDirty', false)
    commit('Notifications/notifyGood', { message: i18n.t('quote.saved') }, { root: true })
  },

  async loadQuote({ commit, dispatch }, { id, withSideEffects = true }) {
    commit('setLoading', true)

    try {
      const data = {
        query: GET_QUOTE_DETAILS,
        variables: { id },
      }

      const { project } = await dispatch('CPQLite/query', data, { root: true })

      if (withSideEffects) {
        commit('clearConfigurations')
        commit('populateFromApiResponse', project)
        dispatch('loadPrices')
      } else {
        return project
      }
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit('setLoading', false)
    }
  },

  /**
   * This fakes duplication of the configuration by keeping the serviceQuotes as they were
   * but resetting the appropriate attributes of the quote configuration, and making sure
   * that we are on a URL without an ID.
   *
   * Although this is not asynchronous, it does need to access the root state, so needs to be
   * an action.
   *
   * @param {object} context
   */
  duplicateConfiguration(context) {
    context.commit('updateQuoteConfig', { key: 'id', value: '' })
    context.commit('updateQuoteConfig', { key: 'projectState', value: '' })
    context.commit('updateQuoteConfig', { key: 'deal', value: { id: '' } })
    context.commit('updateQuoteConfig', { key: 'partner', value: context.rootState.Auth.data.companyName })
    context.commit('updateQuoteConfig', { key: 'createdBy', value: context.rootState.Auth.data.email })
    context.commit('updateQuoteConfig', { key: 'createDate', value: DateTime.utc().toFormat('yyyy-LL-dd\'T\'HH:mm') })

    if (context.rootState.Route.params.quoteId) {
      router.replace({ params: { quoteId: undefined } })
    }
  },

  /**
   * Updates an existing Port/MCR/MVE, changing any attached connections as required (but not updating them with any passed-in values).
   * This is to ensure that currency is kept in sync, and that transit VXCs stay within the same metro.
   *
   * @param {object} context
   * @param {object} payload
   */
  updateServiceAndConnections(context, payload) {
    // Call the standard update mutation
    context.commit('updatePortLikeObject', payload)

    // Now iterate over the list of connections, updating them as required
    const service = context.getters.enhancedServices.find(({ id }) => id === payload.id)

    if (service) {
      for (const connection of service.connectionQuotes) {
        const connectType = connection.connectType?.split('|')[0]

        if (connection.currency !== payload.currency || connectType === 'TRANSIT') {
          // Deep clone so we don't accidentally mutate state
          const updatedConnection = cloneDeep(connection)
          updatedConnection.parentId = service.id

          // Keep the currency in sync
          updatedConnection.currency = service.currency

          // If the connection is a transit VXC, ensure it stays within the same market. If not possible, delete it.
          if (connectType === 'TRANSIT') {
            const portLikeLocation = service._location
            const connectionLocation = context.rootState.Locations.locations.find(location => location.id === connection.bEndLocation)

            const marketMismatch = portLikeLocation.market !== connectionLocation.market

            // If the location of the port-like service has changed and is now in a different market,
            // try to find a transit port in the new market to connect to. If not possible, delete the connection.
            if (marketMismatch) {
              const transitPorts = context.rootGetters['TransitPorts/enhancedTransitPorts']
              const transitEnabledMarkets = context.rootState.TransitPorts.transitEnabledMarkets

              const portLikeIsMve = service.serviceType === G_PRODUCT_TYPE_MVE
              const marketIsTransitEnabled = transitEnabledMarkets.includes(portLikeLocation.market)

              const notifyProps = {
                title: i18n.t('connections.deleted'),
                message: null, // The message will be set based on the reason for deletion
                type: 'warning',
                duration: 5000,
                showClose: false,
              }

              // If the new location is in a transit-enabled market, find a transit port in that market to connect to.
              // NOTE: For MVE port-like services, all markets are transit-enabled.
              // NOTE: For other port-like types, only certain markets are transit-enabled.
              if (portLikeIsMve || marketIsTransitEnabled) {
                const bEndLocation = transitPorts.find(port => port.market === portLikeLocation.market)

                if (bEndLocation) {
                  updatedConnection.bEndLocation = bEndLocation.id
                } else {
                  context.commit('deleteService', connection.id)

                  notifyProps.message = i18n.t('connections.transit-vxc-deleted-no-target', { connectionName: connection.name })

                  this._vm.$notify(notifyProps)
                }
              } else {
                // If the new location is not in a transit-enabled market, delete the connection.
                context.commit('deleteService', connection.id)

                notifyProps.message = i18n.t('connections.transit-vxc-deleted-market-not-enabled', { connectionName: connection.name })

                this._vm.$notify(notifyProps)
              }
            }
          }

          // Call the standard update mutation
          context.commit('updateConnection', updatedConnection)
        }
      }
    }

    // Refresh prices then autosave (don't await because we don't care about the result)
    context.dispatch('loadPricesAndSave')
  },

  /**
   * Use this function to refetch all of our stored prices for this quote. Used mostly to ensure connection prices
   * stay in sync with their parent services, but also used to ensure prices are up to date when loading quotes.
   *
   * @param {object} context
   * @returns {boolean} true if prices were fetched, false if there are no serviceQuotes or the fetch was cancelled
   */
  async loadPrices({ state, commit }) {
    // Nothing to load if there are no serviceQuotes
    if (state.serviceQuotes.length < 1) return false

    commit('setFetchingPrices', true)

    // Cache the signal so we keep the one that was relevant when the request was made
    const abortSignal = state.priceAbortController.signal

    const pricebookRequestSettings = { signal: abortSignal }

    const { companyUid, targetType } = state.quoteConfig

    // Ensure pricing is accurate on a per-company basis
    sdk.instance.effectiveUid = companyUid

    // Now you may look at this and think "You buffoon! Look at all of those unnecessary API calls! Why are you not
    // only calling API-SDK when data has changed?" But I remind you friend that API-SDK caches the data for us, so we
    // can feel free to be as inefficient with our calls as we want, as API-SDK will simply return cached values if they exist.
    const portLikePromises = []
    const connectionPromises = []

    for (const serviceQuote of state.serviceQuotes) {
      const { connectionQuotes, location, rateLimit, serviceType, size, vendor } = serviceQuote
      const { currency: portLikeCurrency, term: portLikeTerm } = serviceQuote
      // Do the price lookup for the port-like service
      switch (serviceType) {
        case G_PRODUCT_TYPE_MEGAPORT:
        case G_PRODUCT_TYPE_LAG:
          portLikePromises.push(pricebook.megaport(location, rateLimit, portLikeTerm, portLikeCurrency, targetType, pricebookRequestSettings))
          break
        case G_PRODUCT_TYPE_MCR2:
          portLikePromises.push(pricebook.mcr(location, rateLimit, portLikeTerm, portLikeCurrency, targetType, pricebookRequestSettings))
          break
        case G_PRODUCT_TYPE_MVE:
          portLikePromises.push(pricebook.mve(location, vendor, size, portLikeTerm, portLikeCurrency, targetType, pricebookRequestSettings))
          break
        default:
          console.error('Product type unknown:', serviceType)
          // Add a dummy promise so our indexes stay in sync
          portLikePromises.push(Promise.resolve({ currency: portLikeCurrency, monthlyRate: 0 }))
      }

      // Do the price lookup on all of it's connections
      const portLikeConnections = []

      for (const connectionQuote of connectionQuotes) {
        const { bEndLocation, connectionType, connectType, ixType, speed } = connectionQuote
        const { currency: connectionCurrency, term: connectionTerm } = connectionQuote
        // Do the price lookup for the connection
        switch (connectionType) {
          case G_PRODUCT_TYPE_IX:
            portLikeConnections.push(pricebook.ix(ixType, location, speed, connectionCurrency, targetType, pricebookRequestSettings))
            break
          case G_PRODUCT_TYPE_VXC:
            {
              const serviceTypeInternal = serviceType === G_PRODUCT_TYPE_LAG ? G_PRODUCT_TYPE_MEGAPORT : serviceType
              const rawConnectType = connectType.split('|')[0]
              const mappedConnectType = rawConnectType === 'PRIVATE' ? 'DEFAULT' : rawConnectType

              const priceData = pricebook.vxc(
                location,
                bEndLocation,
                speed,
                connectionTerm || 1,
                serviceTypeInternal,
                mappedConnectType,
                connectionCurrency,
                targetType,
                pricebookRequestSettings
              )

              portLikeConnections.push(priceData)
            }
            break
          default:
            console.error('Connection type unknown:', connectionType)
            // Add a dummy promise so our indexes stay in sync
            portLikeConnections.push(Promise.resolve({ currency: connectionCurrency, monthlyRate: 0 }))
        }
      }

      // If no connections (i.e. empty array) then JS is a pal and just creates an immediately resolving promise
      connectionPromises.push(Promise.all(portLikeConnections))
    }

    try {
      // Wait for all of the API requests to return, then save the data back to the store
      const prices = await Promise.all([
        Promise.all(portLikePromises),
        Promise.all(connectionPromises),
      ])

      if (abortSignal.aborted) return false

      commit('updatePrices', prices)
    } finally {
      commit('setFetchingPrices', false)
      sdk.instance.effectiveUid = null
    }

    return true
  },

  /**
   * Re-fetches all of the stored prices for the configured quote and saves the quote upon success.
   */
  async loadPricesAndSave({ dispatch }) {
    const pricesFetched = await dispatch('loadPrices')

    if (pricesFetched) {
      await dispatch('saveQuote')
    }
  },
}

const mutations = {
  setLoading(state, tf) {
    state.loading = tf
  },

  /**
   * Adds a Port/MCR/MVE to the list.
   *
   * The shape of the payload should be as follows:
   * {
   *   id: string - uuid of the port-like object. Required so we can track private connections
   *   serviceType: string - 'MEGAPORT', 'MVE', 'MCR2' or 'LAG'
   *   name: string,
   *   location: number,
   *   price: {
   *     monthlyRate: number,
   *   },
   *   rateLimit: number - for 'MEGAPORT', 'MCR2', 'LAG',
   *   portCount: number - for 'LAG',
   *   size: string - for 'MVE',
   *   vendor: string - for 'MVE'
   * }
   *
   * @param {object} state
   * @param {object} payload
   */
  addPortLikeObject(state, payload) {
    const portLike = cloneDeep(payload)
    if (!portLike.connectionQuotes) {
      portLike.connectionQuotes = []
    }
    state.serviceQuotes.push(portLike)
    state.dirty = true
  },

  /**
   * When adding a connection it must specify the parentId so we can find where to connect it to.
   *
   *  The shape of the payload should be as follows
   *  {
   *    parentId: string - so we know what to connect it to (only used internally while creating),
   *    id: string - this is used internally and is OK to be recreated on demand for connections,
   *    connectionType: string 'IX' or 'VXC',
   *    name: string,
   *    price: {
   *      monthlyRate: number,
   *    },
   *    speed: number,
   *    term: number, // For VXC
   *    bEndLocation: number, // For VXC
   *    connectType: string // For VXC
   *    ixType: string // For IX
   *  }
   *
   * @param {object} state
   * @param {object} payload
   */
  addConnection(state, { parentId, ...connection }) {
    const portLike = state.serviceQuotes.find(p => p.id === parentId)
    portLike.connectionQuotes.push({
      ...connection,
      // If no id was provided, we will create one.
      id: connection.id || uuid(),
    })
    state.dirty = true
  },

  /**
   * Updates an existing Port/MCR/MVE. Will replace the entire object with the new payload, but will maintain the
   * connectionQuotes as it was. Must specify the id of the port-like object that you are updating.
   *
   * @param {object} state
   * @param {object} payload
   */
  updatePortLikeObject(state, payload) {
    // Find the service and update it accordingly
    const portLikeIndex = state.serviceQuotes.findIndex(p => p.id === payload.id)
    if (portLikeIndex >= 0) {
      // Update everything except the connectionQuotes
      Vue.set(state.serviceQuotes, portLikeIndex, {
        ...payload,
        connectionQuotes: state.serviceQuotes[portLikeIndex].connectionQuotes,
      })
      state.dirty = true
    }
  },

  /**
   * Updates an existing connection. Will replace the entire object with the new payload, sans parentId. Must specify
   * the id and parentId of the connection you are updating.
   *
   * @param {object} state
   * @param {object} payload
   */
  updateConnection(state, { parentId, ...connection }) {
    const portLike = state.serviceQuotes.find(p => p.id === parentId)
    const index = portLike.connectionQuotes.findIndex(i => i.id === connection.id)
    if (index >= 0) {
      Vue.set(portLike.connectionQuotes, index, connection)
      state.dirty = true
    }
  },

  /**
   * Updates all of the prices in the quote, using an array of tuples
   * containing the service price and its connection prices.
   * The data is matched to state using the array indexes.
   *
   * @param {object} state
   * @param {[Array<Object>, Array<Array<Object>>]} prices
   */
  updatePrices(state, [portLikePrices, connectionPrices]) {
    for (let s = 0; s < portLikePrices.length; s++) {
      const servicePricingData = portLikePrices[s]
      const connectionsPricingData = connectionPrices[s]
      // Update the portLike's pricing data
      state.serviceQuotes[s].price = {
        // The reason we duplicate the currency field is so that we don't display
        // the new currency until the price has been refetched.
        currency: servicePricingData.currency,
        monthlyRate: servicePricingData.monthlyRate,
      }
      // Update the connections' pricing data
      for (let c = 0; c < connectionsPricingData.length; c++) {
        state.serviceQuotes[s].connectionQuotes[c].price = {
          currency: connectionsPricingData[c].currency,
          monthlyRate: connectionsPricingData[c].monthlyRate,
        }
      }
    }
  },

  /**
   * Removes the service from the services list. It can be either a port-like object or a connection.
   *
   * @param {object} state
   * @param {String} id
   */
  deleteService(state, id) {
    const portLikeIndex = state.serviceQuotes.findIndex(p => p.id === id)
    if (portLikeIndex !== -1) {
      const deletedPortLikeObject = cloneDeep(state.serviceQuotes[portLikeIndex])
      state.serviceQuotes.splice(portLikeIndex, 1)
      // Now we need to go through all the other services and see if there were any private connections that rely
      // on this portLike object being here. It is defined as being relevant if the B end is pointing to the location
      // of the deleted port-like and the connectType is a private connection to the type of service that we have
      // just deleted.
      const candidates = []
      const connectType = `PRIVATE|${deletedPortLikeObject.serviceType}`
      for (const portLike of state.serviceQuotes) {
        candidates.push(...portLike.connectionQuotes.filter(connection => connection.connectType === connectType && connection.bEndLocation === deletedPortLikeObject.location))
      }
      // Now for each of the candidates, we see if there is still a port-like object that will support that connection
      // being there.
      for (const candidate of candidates) {
        // See if there is a port that will support it
        let safe = false
        for (const portLike of state.serviceQuotes) {
          if (portLike.serviceType === deletedPortLikeObject.serviceType &&
            portLike.location === deletedPortLikeObject.location &&
            portLike.rateLimit >= candidate.speed) {
            safe = true
            break
          }
        }

        // No port will support the speed of the connection, so delete it
        if (!safe) {
          for (const portLike of state.serviceQuotes) {
            const index = portLike.connectionQuotes.findIndex(i => i.id === candidate.id)
            if (index !== -1) {
              portLike.connectionQuotes.splice(index, 1)
              break
            }
          }
        }
      }

      state.dirty = true
    } else {
      for (const portLike of state.serviceQuotes) {
        const index = portLike.connectionQuotes.findIndex(i => i.id === id)
        if (index !== -1) {
          portLike.connectionQuotes.splice(index, 1)
          state.dirty = true
          break
        }
      }
    }
  },

  /**
   * Remove all configured services.
   *
   * @param {object} state
   */
  clearConfigurations(state) {
    state.serviceQuotes = []
    state.quoteConfig = {
      targetType: '',
      name: '',
      managedCompany: '',
      companyUid: '',
      partner: '',
      id: '',
      createdBy: '',
      createDate: '',
      projectState: '',
      deal: {
        id: '',
      },
    }
    state.dirty = false
    state.loadedFromApi = false
  },

  /**
   * Sets the value for the supplied key in the quote config.
   *
   * @param {object} state
   * @param {object} payload Must contain a key and value to update
   */
  updateQuoteConfig(state, payload) {
    const { key, value } = payload
    Vue.set(state.quoteConfig, key, value)
    state.dirty = true
  },

  /**
   * Refresh the information based on the response from the payload. This could either be direct
   * from the API or from a graphQl fetch, so we need slight adjustments to handle either.
   *
   * @param {object} state
   * @param {object} payload
   */
  populateFromApiResponse(state, payload) {
    for (const key of Object.keys(state.quoteConfig)) {
      Vue.set(state.quoteConfig, key, payload[key])
    }

    // Before we set the array in the store, we want to enhance the connections with ids
    const { serviceQuotes } = cloneDeep(payload)

    for (const serviceQuote of serviceQuotes) {
      // Need to give it a key so the price accumulation works
      serviceQuote.id = uuid()

      // Since the data has come from the graphQl query, then instead of having serviceType, we will have
      // a mixed case __typename, so do the replacement
      serviceQuote.serviceType = serviceQuote.__typename.toUpperCase()
      delete serviceQuote.__typename

      for (const connectionQuote of serviceQuote.connectionQuotes) {
        connectionQuote.id = uuid()

        // Again, we need to process the graphQl __typename
        connectionQuote.connectionType = connectionQuote.__typename.toUpperCase()
        delete connectionQuote.__typename
      }
    }

    Vue.set(state, 'serviceQuotes', serviceQuotes)

    state.dirty = false
    state.loadedFromApi = true
  },

  setDirty(state, tf) {
    state.dirty = tf
  },

  /**
   * Flags the store as currently fetching prices. Also kills existing price requests using priceAbortController if
   * the flag is set to true while it is already true
   *
   * @param {boolean} fetchingPrices The new state of the fetchingPrices flag
   */
  setFetchingPrices(state, fetchingPrices) {
    if (state.fetchingPrices && fetchingPrices) {
      // Kill any in-progress price fetches as they may be outdated
      state.priceAbortController.abort()
      state.priceAbortController = new AbortController()

    } else {
      state.fetchingPrices = fetchingPrices
    }
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
