/* Data demo fields */
const demoCallback = e => {
  const demo = e.target.getAttribute('data-demo')
  if (e.metaKey && demo) {
    e.target.value = demo
    const event = new Event('input', {
      bubbles: true,
    })
    e.target.dispatchEvent(event)
  }
}

document.addEventListener('click', demoCallback)
document.addEventListener('input', demoCallback)
document.addEventListener('touchstart', demoCallback, false)
