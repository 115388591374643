import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/i18n/en-setup.js'
import ElementLocale from 'element-ui/lib/locale'
import { Settings } from 'luxon'

let i18n = null
// We cannot load up i18n when running tests because we lose the ability to set up the v-t directive
if (import.meta.env.NODE_ENV !== 'test') {
  Vue.use(VueI18n)

  i18n = new VueI18n({
    locale: getLocalStorage(),
    fallbackLocale: 'en',
    messages: { en },
    silentTranslationWarn: true,
  })

  ElementLocale.i18n((key, value) => i18n.t(key, value))
}

const loadedLanguages = ['en']

if (getLocalStorage() !== 'en') {
  loadLanguageAsync(getLocalStorage())
}

/**
 * Set the i18n plugin language
 * @param {string} lang The language abbreviation
 * @returns
 */
export function setI18nLanguage(lang) {
  i18n.locale = lang
  Settings.defaultLocale = lang
  Settings.resetCaches()
  document.querySelector('html').setAttribute('lang', lang)

  return null
}

/**
 * Set the language as a cookie/localStorage
 * Cookies are primary source
 * Some ad blockers block cookies; localStorage is the secondary source
 * @param {string} lang The selected language abbreviation
 */
function setLocalStorage(lang) {
  document.cookie = `mp_lang=${lang};domain=megaport.com; path=/;`
  window.localStorage.setItem('mp_lang', lang)
}

/**
 * Get the language from cookie/localStorage
 * @returns The selected language abbreviation
 */
function getLocalStorage() {
  const cookies = new URLSearchParams(document.cookie.replace(/; /g, '&'))
  const localStore = window.localStorage.getItem('mp_lang') || 'en'
  return cookies.has('mp_lang') ? cookies.get('mp_lang') : localStore
}

/**
 * Dynamic import for runtime async chunk loading
 * @param {string} lang language abbreviation
 * @returns
 */
export function loadLanguageAsync(lang = getLocalStorage()) {
  setLocalStorage(lang)

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(`@/i18n/${lang || 'en'}-setup.js`).then(messages => {
    i18n.setLocaleMessage(lang, messages.default)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

export default i18n
