import * as globals from '@/Globals.js'
import { DateTime } from 'luxon'
import sdk, { CanceledError } from '@megaport/api-sdk'

const TRANSIT_VXC_MIN_SPEED = 20
const DEFAULT_VXC_MIN_SPEED = 1
const TEN_GBPS = 10000

/**
 * Determine the minimum VXC speed
 * @param bLocation
 * @returns {number}
 */
export function determineMinVxcSpeed(bLocation) {
  const isTransit = bLocation?.connectType === 'TRANSIT'

  return isTransit ? TRANSIT_VXC_MIN_SPEED : DEFAULT_VXC_MIN_SPEED
}

/**
 * Determine the maximum VXC speed
 * @param aPort
 * @param bLocation
 * @returns {number}
 */
export function determineMaxVxcSpeed(aPort, bLocation) {
  // If the connection is to another user's private service,
  // then we don't know what is at the other end,
  // so just go with the speed from the end we know.
  let maxSpeedA = aPort?.maxVxcSpeed || aPort?._aggSpeed || Number.MAX_SAFE_INTEGER
  let maxSpeedB = bLocation?.maxVxcSpeed || bLocation?._aggSpeed || Number.MAX_SAFE_INTEGER

  // MVE remains capped at 10G for VXCs
  if (aPort?.serviceType === globals.G_PRODUCT_TYPE_MVE) {
    maxSpeedA = TEN_GBPS
  }

  if (bLocation?.serviceType === globals.G_PRODUCT_TYPE_MVE) {
    maxSpeedB = TEN_GBPS
  }

  // Transit/Megaport Internet is still capped at 10G for VXCs
  if (bLocation?.connectType === 'TRANSIT') {
    maxSpeedB = TEN_GBPS
  }

  return Math.min(maxSpeedA, maxSpeedB)
}

/**
 * Determine the max speed for IX connections
 * @param aPort
 * @param bPort
 * @returns {number}
 */
export function determineMaxIxSpeed(aPort, bPort) {
  let maxSpeed = aPort._aggSpeed

  // Local IX
  if (aPort && bPort && aPort._location && aPort._location.metro === bPort.group_metro) {
    return maxSpeed
  }

  // For remote IX use maxVxcSpeed if we have it, otherwise fallback to _aggSpeed.
  return aPort.maxVxcSpeed || maxSpeed
}

export const convertSpeed = function(speed) {
  if (!speed) return '-'
  if (speed >= 1000) return `${speed / 1000} Gbps`
  return `${speed} Mbps`
}

export const convertProductType = productType => {
  switch (productType) {
    case globals.G_PRODUCT_TYPE_MEGAPORT:
      return window.mpApp.$t('productNames.port')
    case globals.G_PRODUCT_TYPE_MCR2:
      return window.mpApp.$t('productNames.mcr')
    case globals.G_PRODUCT_TYPE_MVE:
      return window.mpApp.$t('productNames.mve')
    case globals.G_PRODUCT_TYPE_VXC:
      return window.mpApp.$t('productNames.vxc')
    case globals.G_PRODUCT_TYPE_IX:
      return window.mpApp.$t('productNames.ix')
    case globals.G_PRODUCT_TYPE_LAG:
      return window.mpApp.$t('productNames.lag')
    default:
      return productType
  }
}

export function getProductIconByType(type) {
  switch (type) {
    case globals.G_PRODUCT_TYPE_MEGAPORT:
      return 'MegaportIcon'
    case globals.G_PRODUCT_TYPE_MCR2:
      return 'McrIcon'
    case globals.G_PRODUCT_TYPE_MVE:
      return 'MveIcon'
    case globals.G_PRODUCT_TYPE_VXC:
      return 'VxcIcon'
    case globals.G_PRODUCT_TYPE_IX:
      return 'IxIcon'
    case globals.G_PRODUCT_TYPE_LAG:
      return 'LagIcon'
    default:
      return ''
  }
}

export const capitalizeFirstOnly = string => {
  if (!string) return ''

  return `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`
}

/**
 * Implementation of https://github.com/tc39/proposal-promise-with-resolvers which is currently Stage 3
 * @returns An object containing a new promise and it's resolve/reject functions
 */
export const promiseWithResolvers = () => {
  let resolve, reject

  const promise = new Promise((res, rej) => {
    resolve = res
    reject = rej
  })

  return { promise, resolve, reject }
}

export const mpDate = value => {
  let date = value ? DateTime.fromMillis(value) : DateTime.now()

  // Parse value if not in milliseconds
  if (date.invalid) {
    date = DateTime.fromMillis(Date.parse(date))
  }

  return date.toFormat('LLLL d y, t ZZZZZ')
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */
export const moneyFilter = (value, currency) => {
  const standardCurrency = (currency || 'USD').toUpperCase()

  // when undefined is passed as locale, the currency will be displayed as per the browser location
  // ie. ie. if the browser location is Germany, Euro will be displayed as 123.456,79 €,
  // whereas if the location is any English speaking country like Australia, it will be displayed as €123,456.79
  let currencyValue = new Intl.NumberFormat(undefined, { style: 'currency', currency: standardCurrency }).format(value)

  if (!value) {
    currencyValue = currencyValue.replace('NaN', ' -')
  }

  // This stops the oddity of some currencies returning a currency code sandwich (e.g. "SGD 500.00 SGD")
  return currencyValue.includes(standardCurrency)
    ? currencyValue
    : `${currencyValue} ${standardCurrency}`
}

// All pricing information is here
export const pricebook = {
  /**
   * NOTE: The prices returned include any discounts that are applied for that service or company.
   *
   * If you don't specify a buyout port, it will still work.
   * If you don't specify productUid or specify one that isn't valid, it will treat it as a new purchase.
   *
   * All of the below methods return a monthlyRate field
   */

  /**
   * Get pricing information for ports
   * @param {Number} locationId
   * @param {Number} speed
   * @param {Number} term
   * @param {String} currency
   * @param {String} targetType
   * @param {Object} [settings] API-SDK settings to use when making the request
   * @returns {Object} Object containing pricing info
   */
  async megaport(locationId, speed, term, currency, targetType, settings) {
    // Check if all required data has been passed first before calling pricebook
    if (!locationId || !speed || !currency) return

    try {
      return await sdk.instance.priceBook()
        .megaport({
          locationId,
          speed,
          term: term || 1,
          currency,
          targetType,
        }, settings)
    } catch (error) {
      if (error instanceof CanceledError) return
      throw error
    }
  },
  /**
   * Get pricing information for MCRs
   * @param {Number} locationId
   * @param {Number} speed
   * @param {Number} term
   * @param {String} currency
   * @param {String} targetType
   * @param {Object} [settings] API-SDK settings to use when making the request
   * @returns {Object} Object containing pricing info
   */
  async mcr(locationId, speed, term, currency, targetType, settings) {
    // Check if all required data has been passed first before calling pricebook
    if (!locationId || !speed || !currency) return

    try {
      return await sdk.instance.priceBook()
        .mcr({
          locationId,
          speed,
          term: term || 1,
          currency,
          targetType,
        }, settings)
    } catch (error) {
      if (error instanceof CanceledError) return
      throw error
    }
  },
  /**
   * Get pricing information for MVEs
   * @param {Number} locationId
   * @param {String} vendor
   * @param {String} size
   * @param {Number} term
   * @param {String} currency
   * @param {String} targetType
   * @param {Object} [settings] API-SDK settings to use when making the request
   * @returns {Object} Object containing pricing info
   */
  async mve(locationId, vendor, size, term, currency, targetType, settings) {
    // Check if all required data has been passed first before calling pricebook
    if (!locationId || !vendor || !size || !currency) return

    try {
      return await sdk.instance.priceBook()
        .mve({
          locationId,
          vendor,
          size,
          term: term || 1,
          currency,
          targetType,
        }, settings)
    } catch (error) {
      if (error instanceof CanceledError) return
      throw error
    }
  },
  /**
   * Get pricing information for VXCs
   * @param {Number} aLocationId
   * @param {Number} bLocationId
   * @param {Number} speed
   * @param {Number} term
   * @param {String} aEndProductType
   * @param {String} connectType
   * @param {String} currency
   * @param {String} targetType
   * @param {Object} [settings] API-SDK settings to use when making the request
   * @returns {Object} Object containing pricing info
   */
  async vxc(aLocationId, bLocationId, speed, term, aEndProductType, connectType, currency, targetType, settings) {
    // Check if all required data has been passed first before calling pricebook
    if (!aLocationId || !bLocationId || !speed || !aEndProductType || !connectType || !currency) return

    try {
      return await sdk.instance.priceBook()
        .vxc({
          aLocationId,
          speed,
          term,
          bLocationId,
          aEndProductType,
          connectType: connectType ? connectType.toUpperCase() : 'DEFAULT',
          currency,
          targetType,
        }, settings)
    } catch (error) {
      if (error instanceof CanceledError) return
      throw error
    }
  },
  /**
   * Get pricing information for IXs
   * @param {String} ixType
   * @param {Number} portLocationId
   * @param {Number} speed
   * @param {String} currency
   * @param {String} targetType
   * @param {Object} [settings] API-SDK settings to use when making the request
   * @returns {Object} Object containing pricing info
   */
  async ix(ixType, portLocationId, speed, currency, targetType, settings) {
    // Check if all required data has been passed first before calling pricebook
    if (!ixType || !portLocationId || !speed || !currency) return

    try {
      return await sdk.instance.priceBook()
        .ix({
          ixType,
          portLocationId,
          speed,
          currency,
          targetType,
        }, settings)
    } catch (error) {
      if (error instanceof CanceledError) return
      throw error
    }
  },
}

Array.prototype.unique = function() {
  return this.reduce(function(accum, current) {
    if (accum.indexOf(current) < 0) {
      accum.push(current)
    }
    return accum
  }, [])
}

export const copyNodeToClipboard = (node, withStyling = true) => {
  let success
  if (withStyling) {
    // Clear the existing selection
    window.getSelection()
      .removeAllRanges()

    // Select the node to be copied
    const range = document.createRange()
    range.selectNode(node)
    window.getSelection()
      .addRange(range)

    // I know this is flagged as deprecated, but Firefox straight up does not support the
    // new standard for copying to the clipboard, so we must use the old deprecated system
    success = document.execCommand('copy')

    // Clear the node selection
    window.getSelection()
      .removeAllRanges()
  } else {
    // Use an event listener to intercept the copy and manually insert the styleless HTML
    const removeStyling = event => {
      event.clipboardData.setData('text/html', node.innerHTML )
      event.clipboardData.setData('text/plain', node.innerHTML )
      event.preventDefault()
    }
    document.addEventListener('copy', removeStyling)
    success = document.execCommand('copy')
    document.removeEventListener('copy', removeStyling)
  }

  if (success) {
    window.mpApp.$notify.success({
      title: window.mpApp.$t('general.copy-to-clipboard'),
      message: window.mpApp.$t('general.copied-to-clipboard', { target: window.mpApp.$t('general.network-design') }),
    })
  } else {
    window.mpApp.$notify.error({
      title: window.mpApp.$t('general.copy-to-clipboard'),
      message: window.mpApp.$t('general.copy-failed'),
    })
  }
}

export const randomNumber = () => {
  const crypto = window.crypto || window.msCrypto
  const array = new Uint32Array(1)
  const [val] = crypto.getRandomValues(array)
  return val / (Math.pow(2, 32) - 1)
}

/**
 * Set fallback image if src image does not exist and does not already have a fallback image
 * @param {Object} $event Event argument
 * @param {String} fallbackImage Fallback image URL
 */
export const setFallbackImage = ($event, fallbackImage) => {
  if ($event.target.src !== fallbackImage) $event.target.src = fallbackImage
}

/**
 *
 * @param {number} term service term provided to get the text for
 * @param {boolean} includeTermSuffix should include word Term as suffix
 * @returns {string} formatted service term
 */
export const getTermText = (term, includeTermSuffix = false) => {
  let termText
  if (term === 1) {
    termText = window.mpApp.$t('general.monthly-subscription')
  } else {
    termText = `${window.mpApp.$tc('general.count-months', term, { count: term })}`
  }

  if (includeTermSuffix) {
    termText = `${termText} ${window.mpApp.$t('general.term')}`
  }
  return termText
}

export class GraphQLError extends Error {
  constructor(graphQLErrors) {
    // For now just use the error message from the first error
    super(graphQLErrors[0].message)
    this.name = 'GraphQLError'
  }
}

/**
 * Escape special characters in a string
 * @param {string} string
 * @returns {string} escaped string
 */
export const escapeSpecialCharacters = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
