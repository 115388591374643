import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import integrations from '@/third-party-integrations/integrations.js'

const HomeView = () => import('@/views/Home.vue')
const LoginView = () => import('@/views/Login.vue')
const MfaFormView = () => import('@/views/MfaForm.vue')
const PostLoginView = () => import('@/views/PostLogin.vue')
const PrintView = () => import('@/views/Print.vue')
const QuoteBuilderView = () => import('@/views/QuoteBuilder.vue')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: import.meta.env.PUBLIC_PATH || '/',
  routes: [
    {
      path: '/',
      component: HomeView,
      meta: {
        requiresLogin: true,
      },
    },
    {
      path: '/login',
      component: LoginView,
      meta: {
        requiresLogin: false,
      },
    },
    {
      path: '/mfa',
      component: MfaFormView,
      meta: {
        requiresLogin: false,
      },
    },
    {
      path: '/post-login',
      component: PostLoginView,
      meta: {
        requiresLogin: false,
      },
    },
    {
      path: '/pdf/:id',
      component: PrintView,
      meta: {
        requiresLogin: true,
        template: 'print',
      },
    },
    {
      path: '/quote-builder/:quoteId?',
      component: QuoteBuilderView,
      meta: {
        requiresLogin: true,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresLogin && !store.getters['Auth/jwt']) {
    let accessToken = localStorage.getItem('_accessToken')

    if (accessToken) {
      let loading = null
      if (router.app) {
        loading = router.app.$loading({
          lock: true,
          text: 'Logging you in...',
          background: 'rgba(255, 255, 255, 0.9)',
        })
      }

      const payload = { accessToken }
      store.dispatch('Auth/login', { payload })
        .then(() => {
          if (loading) loading.close()
          next(to)
        })
        .catch(e => {
          console.error(e)
          loading.close()
          const props = {
            title: 'Please Log In',
            message: 'Failed to log you in with your existing token. Please log in again.',
            type: 'error',
            duration: 10000,
          }
          if (router.app) {
            router.app.$notify(props)
          }
          console.info('Failed to login - redirecting')
          next('/login')
        })
    } else {
      next('/login')
    }
  }

  next()
})

router.afterEach((to, from) => {
  integrations.segment.recordPageView(to, from)
})


export default router
