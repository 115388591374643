import * as actions from './RootActions.js'

import Auth from './modules/Auth.js'
import CPQLite from './modules/CPQLite.js'
import Quotes from './modules/Quotes.js'
import Mve from './modules/Mve.js'
import IxTypes from './modules/IxTypes.js'
import Notifications from './modules/Notifications.js'
import CSPs from './modules/CSPs.js'
import Locations from './modules/Locations.js'
import ConfiguredServices from './modules/ConfiguredServices.js'
import TransitPorts from './modules/TransitPorts.js'
import Partner from './modules/Partner.js'


export const storeConfig = {
  modules: {
    Auth,
    CPQLite,
    Quotes,
    Mve,
    IxTypes,
    Notifications,
    CSPs,
    Locations,
    ConfiguredServices,
    TransitPorts,
    Partner,
  },
  state: () => {},
  actions,
}

export default storeConfig
