import * as globals from '@/Globals.js'

const getters = {
  getMves: (_state, _gettersArg, rootState) => {
    return rootState.ConfiguredServices.services.filter(service => {
      return service.productType === globals.G_PRODUCT_TYPE_MVE
    })
  },
  getMve: (_state, gettersArg) => productUid => {
    return gettersArg.getMves.find(service => service.productUid === productUid)
  },
  getAvailableMveLocations: (_state, _gettersArg, rootState) => {
    return rootState.Locations.locations.filter(location => {
      return location.products.mve ? true : false
    }).filter(location => ['Deployment', 'Active'].includes(location.status))
  },
  getMveVendors: (_state, gettersArg) => {
    return gettersArg.getAvailableMveLocations
      .map(location => location.products.mve)
      .flat()
      .map(item => item.vendor)
      .unique()
  },
  getLocationsForVendor: (_state, gettersArg) => vendor => {
    const locations = []
    for (const location of gettersArg.getAvailableMveLocations) {
      const mveImages = location.products.mve
      if (mveImages.find(item => item.vendor === vendor)) {
        locations.push(location)
      }
    }
    return locations
  },
  getImagesForLocationAndVendor: (_state, gettersArg) => (locationId, vendor) => {
    const location = gettersArg.getAvailableMveLocations.find(({ id }) => id === locationId)

    return location.products.mve.filter(mve => mve.vendor === vendor)
  },
  getImageForLocationAndImageId: (_state, gettersArg) => (locationId, imageId) => {
    const location = gettersArg.getAvailableMveLocations
      .find(l => l.id === locationId)
    return location.products.mve.find(mve => mve.id === imageId)
  },
}

const actions = {
}

const mutations = {

}

export default {
  namespaced: true,
  state: {},
  getters,
  actions,
  mutations,
}
