import axios from 'axios'

let axiosInstance

export const initialiseAxiosInstance = baseURL => {
  if (axiosInstance !== undefined) {
    console.warn('CPQLite API already initialised')
    return
  }

  axiosInstance = axios.create({ baseURL })
}

export const handleRequest = async (method, url, config = {}) => {
  const response = await axiosInstance.request({ method, url, ...config })
  return response.data
}

export const post = (url, config) => handleRequest('POST', url, config)
