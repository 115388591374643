import sdk from '@megaport/api-sdk'

const initialState = {
  ready: false,
  locations: [],
}

const getters = {
  // Get all the locations in the enabled markets
}

const actions = {
  /**
   * Load the list of locations.
   *
   * @param {object} context
   */
  async loadLocations(context) {
    try {
      const [locations, markets] = await Promise.all([
        sdk.instance.lists('locations'),
        sdk.instance.lists('markets'),
      ])

      const indexedMarkets = markets.reduce((accumulator, market) => ({
        ...accumulator,
        [market.market]: market,
      }), {})

      const mappedLocations = locations.map(location => {
        const market = indexedMarkets[location.market]
        return {
          ...location,
          formatted: {
            short: `${location.name}, ${location.address.city}`,
            long: `${location.name}, ${location.address.city}, ${location.address.country}`,
          },
          currencies: market.availableCurrencies,
          defaultCurrency: market.marketCurrency,
        }
      })

      context.commit('setLocations', mappedLocations)
      context.commit('setReady', true)
    } catch (error) {
      // Just silently ignore it for the moment
    }
  },
}

const mutations = {
  setReady(state, tf) {
    state.ready = tf
  },

  setLocations(state, locations) {
    state.locations = locations
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
