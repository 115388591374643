<template>
  <div id="app">
    <header v-if="$route.meta.requiresLogin"
      class="menu-header">
      <!-- App Logo -->
      <div class="menu-logo">
        <router-link to="/">
          <el-tooltip :content="`${$t('app-info.name-short')} v${appVersion} - ${gitVersion}`"
            :open-delay="500">
            <img src="@/assets/images/megaport-logo.svg"
              :alt="$t('general.megaport-logo')"
              width="164"
              height="47">
          </el-tooltip>
        </router-link>
      </div>

      <!-- Actions "Menu" -->
      <el-menu mode="horizontal"
        class="menu-bar">
        <!-- Documentation -->
        <el-menu-item @click="showDocumentation">
          {{ $t('general.documentation') }}
        </el-menu-item>

        <!-- User "Sub-Menu" -->
        <el-submenu index="1"
          popper-class="sub-menu-popper"
          :aria-label="$t('menu.user-menu')">
          <template slot="title">
            <font-awesome-icon :icon="['fas', 'user']"
              size="xl" />
          </template>
          <el-menu-item @click="logout">
            {{ $t('authentication.log-out') }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </header>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import gitVersion from '@/gitVersion.js'

export default {
  name: 'App',

  inject: ['isFeatureEnabled'],

  data() {
    return {
      appVersion: global.VITE_APP_VERSION,
      gitVersion: gitVersion,
    }
  },

  computed: {
    ...mapGetters('Auth', ['isPartnerAccount']),

    isPvPartner() {
      return this.isPartnerAccount && this.isFeatureEnabled('VANTAGE_PARTNER')
    },
  },

  created() {
    this.$store.dispatch('onInit')
  },

  methods: {
    showDocumentation() {
      if (this.isPvPartner) {
        window.open('https://partner-docs.megaport.com/services/creating-estimates/', '_blank')
      } else {
        window.open('https://docs.megaport.com/finance/creating-estimates/', '_blank')
      }
    },

    async logout() {
      try {
        await this.$store.dispatch('Auth/logout')
      } finally {
        this.$router.push('/login')
      }
    },
  },
}
</script>

<style lang="scss">
svg {
  display: block; // Gets rid of the annoying space around the icons
}

.el-loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-header {
  z-index: 10;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  padding-inline: 2rem;
  background-color: #fff;
}

.menu-bar {
  display: flex;
  align-items: center;
  border-bottom: 0 !important;
}

.sub-menu-popper > ul[role="menu"] {
  margin-top: 1.5rem;
}
</style>
