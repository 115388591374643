// onLogout is registered at the top level so that it can easily clean up
// the entire data tree.
export const onLogout = context => {
  context.commit('Auth/logout')
  context.commit('ConfiguredServices/clearConfigurations')
}

export const onLogin = async context => {
  // Always start with a clean slate so that even if there was something that bypassed
  // logout you still start with no services configured.
  context.dispatch('Locations/loadLocations')

  // Wait for CSPs to load before loading transit VXCs as API-SDK caches the partnerPorts list
  await context.dispatch('CSPs/loadPartnerData')

  context.dispatch('TransitPorts/loadTransitPorts')

  context.dispatch('TransitPorts/fetchTransitEnabledMarkets')

  // Load the managed accounts for partners
  if (context.getters['Auth/isPartnerAccount']) {
    await context.dispatch('Partner/getManagedAccounts')
  }

  window.mpApp.$router.push('/', () => { })
}

// Actions to fire off during initial setup - mainly data that doesn't change often.
export const onInit = context => {
  context.dispatch('IxTypes/refreshIxTypes')
}
