/**********************/
/* Globally available */
/**********************/
export const G_PRODUCT_TYPE_MEGAPORT = 'MEGAPORT'
export const G_PRODUCT_TYPE_MCR2 = 'MCR2'
export const G_PRODUCT_TYPE_MVE = 'MVE'
export const G_PRODUCT_TYPE_VXC = 'VXC'
export const G_PRODUCT_TYPE_IX = 'IX'
export const G_PRODUCT_TYPE_LAG = 'LAG'

export const G_FIELD_TYPE_TEXT = 'text'
export const G_FIELD_TYPE_SELECT = 'select'

export const G_QUOTE_STATUS_REGISTERED = 'REGISTERED'
export const G_QUOTE_STATUS_SAVED = 'SAVED'

/********************/
/*   Specific use   */
/********************/

// determine client portal url from current url. localhost uses the default dev url
let { protocol = '', host = '' } = window.location
export const PORTAL_URL = host.startsWith('localhost')
  ? 'http://localhost:8181'
  : `${protocol}//${host.replace('pricing', 'portal')}`

export const LOGIN_COGNITO = 'cognito'
export const LOGIN_GOOGLE = 'google'

export const X_APP = 'quoting_tool'

// People are only allowed to log in with megaport accounts or PV partner accounts.
export const MEGAPORT_ID = 1
export const MEGAPORT_LAB_ID = 1153

export const ACCOUNT_TYPE_ADMIN = 'ADMIN'
export const ACCOUNT_TYPE_DIRECT_CUSTOMER = 'DIRECT_CUSTOMER'
export const ACCOUNT_TYPE_MANAGED_ACCOUNT = 'MANAGED_ACCOUNT'
export const ACCOUNT_TYPE_PARTNER = 'PARTNER'
/********************/
/*      Vendors     */
/********************/
export const CISCO_VENDOR = 'Cisco'
export const FORTINET_VENDOR = 'Fortinet'
export const VMWARE_VENDOR = 'VMware'
export const VERSA_VENDOR = 'Versa'

export const AWS_COMPANY = '605cb850-dfb4-4a05-a171-8bf17757b3a2'
export const FRANCEIX_UID = 'ce291fed-90b2-46df-8b39-f87342bd0de7'

// So we can do special handling for SAP connections
export const SAP_DETAILS = {
  companyUid: '7769fd21-9ac5-41ed-aed4-bb4615f1cbe0',
  companyName: 'SAP',
  connectType: 'SAP',
}

// The first of the companyUids will be the one that is used in the target select listing,
// and will be matched with the appropriate icon.
export const CLOUD_ITEMS = [
  {
    connectType: 'OUTSCALE',
    title: '3DS OUTSCALE',
    companyUids: ['1bfc203b-b9a3-46c1-85a3-e4eab6abf439'],
    hasKeyValidation: false,
  },
  {
    connectType: 'ALIBABA',
    title: 'Alibaba Cloud',
    companyUids: ['182ef3f4-5b74-4adf-9710-6f37a01be434'],
    hasKeyValidation: false,
  },
  {
    connectType: ['AWS', 'AWSHC'],
    title: 'Amazon Web Services',
    companyUids: [AWS_COMPANY],
    hasKeyValidation: false,
  },
  {
    connectType: 'AMSIX',
    title: 'AMS-IX',
    companyUids: ['cd92fac4-7f75-4fb0-bd24-19dcb9ed3e8e'],
    hasKeyValidation: false,
  },
  {
    connectType: 'GOOGLE',
    title: 'Google Cloud',
    companyUids: ['29ba879b-45c8-48eb-bd97-618d0f20ea04'],
    hasKeyValidation: true,
  },
  {
    connectType: 'IBM',
    title: 'IBM Cloud',
    companyUids: ['dfaa7d3f-450b-4a1e-9026-27cb02c5098d'],
    hasKeyValidation: false,
  },
  {
    connectType: 'AZURE',
    title: 'Microsoft Azure',
    companyUids: ['c40de5ed-4707-4471-a78c-69436de2c505'],
    hasKeyValidation: true,
  },
  {
    connectType: 'ORACLE',
    title: 'Oracle Cloud',
    companyUids: ['8ea83603-445c-40c6-94cf-67967a18beb9'],
    hasKeyValidation: true,
  },
  {
    connectType: 'NUTANIX',
    title: 'Nutanix',
    companyUids: ['0dda2b1b-6129-4916-adbe-417cefdcaef4', '7752dde1-1cd2-4633-adad-f132a733ff78'],
    hasKeyValidation: true,
  },
  {
    connectType: 'SFDC',
    title: 'Salesforce',
    companyUids: ['98ab066a-b945-4369-ad72-ee5a8dc35fbc'],
    hasKeyValidation: false,
  },
  {
    connectType: SAP_DETAILS.connectType,
    title: SAP_DETAILS.companyName,
    companyUids: [SAP_DETAILS.companyUid],
    hasKeyValidation: false, // This is handled using the normal service key processing
  },
  {
    connectType: 'IX',
    title: 'Internet Exchange',
    megaIcon: 'IX',
    hasKeyValidation: false,
    companyUids: [],
  },
]

export const TARGET_TYPES = {
  EXISTING_COMPANY: 'EXISTING',
  NEW_COMPANY: 'NEW',
  SELF: 'SELF',
}
