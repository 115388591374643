import sdk from '@megaport/api-sdk'
import i18n from '@/i18n/SetupI18n'

const initialState = {
  managedAccounts: [],
}

const getters = {
  sortedManagedAccounts: state => {
    return state.managedAccounts
      .map(account => ({
        managementEmail: account.accountManagementEmail,
        name: account.accountName,
        reference: account.accountRef,
        companyId: account.companyId,
        companyUid: account.companyUid,
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  },
}

const actions = {
  async getManagedAccounts({ commit }) {
    try {
      const managedAccounts = await sdk.instance.managedCompanies().getManagedCompanies()
      commit('setManagedAccounts', managedAccounts)
    } catch (error) {
      console.error('Error getting managed accounts', error)
      commit('Notifications/notifyBad', { message: i18n.t('partner.error-loading-managed-accounts') }, { root: true })
    }
  },
}

const mutations = {
  setManagedAccounts(state, managedAccounts) {
    state.managedAccounts = managedAccounts
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
