import Vue from 'vue'

const runtimeProviders = Vue.observable({
  companyConfiguration: {
    consolidatedSettings: {
      COMPANY_PROFILE_ENABLED: true,
      DASHBOARD_ENABLED: true,
      JIRA_CHAT_ENABLED: true,
      MARKETPLACE_PROFILE_ENABLED: true,
      PAYS_BILL: true,
      PRICING_VISIBLE: true,
      PRODUCT_TYPE_IX: false,
      PRODUCT_TYPE_MCR2: false,
      PRODUCT_TYPE_MVE: false,
      PRODUCT_TYPE_PORT: false,
      PRODUCT_TYPE_VXC: false,
      RECEIVES_NOTIFICATIONS: false,
      REGISTRATION_TYPE_EMAIL_LINK: false,
      REGISTRATION_TYPE_USER_PROVIDES_CREDENTIALS: false,
    },
  },
})

/**
 * Update the runtime provider with a given path and value
 * @param {Array} path Object node property name
 * @param {String|Boolean} value Property value
 * @returns Function<Object:runtimeProvider>
 */
export function updateRuntimeFeatureFlag(path, value) {
  function nestedUpdate(obj, nestedKey = null) {
    return path.reduce((acc, key) => {
      const curKey = nestedKey ? nestedKey : key

      if (acc[curKey] instanceof Object) {
        return nestedUpdate(acc[key], curKey)
      }

      if (curKey === path[path.length - 1]) acc[curKey] = value
      return acc
    }, obj)
  }

  return nestedUpdate(runtimeProviders)
}

/**
 * Check the runtime feature flag is enabled
 * @param {String} key feature name
 * @returns {Boolean} value
 */
export function isFeatureEnabled(key) {
  return !!runtimeProviders.companyConfiguration.consolidatedSettings?.[key]
}

export default { ...runtimeProviders, updateRuntimeFeatureFlag, isFeatureEnabled }
