import Vue from 'vue'
import sdk from '@megaport/api-sdk'

// For data store caching of IX locations.
const initialState = {
  ixTypes: {},
}

const getters = {}

const actions = {
  // Get the data from the cache if we have it, and otherwise load it.
  async getIxType(context, locationId) {
    if (context.state.ixTypes[locationId]) {
      return context.state.ixTypes[locationId]
    }

    try {
      const locations = await sdk.instance.ixTypes(locationId)

      locations.sort((a, b) => {
        const aDesc = a.description || a.name
        const bDesc = b.description || b.name

        return aDesc.toLowerCase() < bDesc.toLowerCase() ? -1 : 1
      })

      context.commit('setIxTypes', { locationId, locations })

      return context.state.ixTypes[locationId]
    } catch (e) {
      console.error(e)
    }
  },

  // Periodically refresh the data - It's not likely to change often, but just to make sure.
  refreshIxTypes(context) {
    Object.keys(context.state.ixTypes).forEach(locationId => {
      sdk.instance
        .ixTypes(locationId)
        .then(locations => {
          locations.sort((a, b) => {
            const aDesc = a.description || a.name
            const bDesc = b.description || b.name

            return aDesc.toLowerCase() < bDesc.toLowerCase() ? -1 : 1
          })

          context.commit('setIxTypes', { locationId, locations })
        })
        .catch(err => console.error(err))
    })

    setTimeout(() => context.dispatch('refreshIxTypes'), 30 * 60 * 1000) // 30 minutes
  },
}

const mutations = {
  setIxTypes(state, { locationId, locations }) {
    Vue.set(state.ixTypes, locationId, locations)
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
