// External tools
import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import MegaportSDK from '@megaport/api-sdk'
// Internal tools
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n/SetupI18n.js'
import runtimeProviders from '@/providers/runtime.js'
import { setupIntegrations } from '@/third-party-integrations/integrations.js'
import { handleSessionTimeout } from '@/AuthHelpers.js'
import { initialiseAxiosInstance } from '@/CPQLiteFetcher'
// Plugins
import GlobalMixinPlugin from '@/mixins/global.js'
import '@/plugins/element.js'
// Icons
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { faCog, faTrashAlt, faPlusCircle, faClipboard, faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { faQuestionCircle, faUser, faFileCsv, faFilePdf, faFileCode, faFileLines } from '@fortawesome/pro-solid-svg-icons'
// Demo data
import '@/demoData.js'
// Global variables
import { X_APP } from '@/Globals.js'

import App from '@/App.vue'

Vue.use(GlobalMixinPlugin)
Vue.use(AsyncComputed)

// Add font awesome icons to the library
library.add(
  // Regular
  faEye,
  faEyeSlash,
  // Light
  faCog,
  faTrashAlt,
  faPlusCircle,
  faClipboard,
  faAngleDown,
  // Solid
  faQuestionCircle,
  faUser,
  faFileCsv,
  faFilePdf,
  faFileCode,
  faFileLines
)

// Register the font awesome icon component
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

import '@/helpers.js'

Vue.config.devtools = true
Vue.config.performance = true
Vue.config.productionTip = false

// Dynamic import environment file
import(/* @vite-ignore */ `${import.meta.env.BASE_URL}environment.js`).then(async environment => {
  // Environment
  const appConfiguration = environment.default
  Vue.prototype.$appConfiguration = appConfiguration
  // CPQLite API
  initialiseAxiosInstance(appConfiguration.cpqApi)
  // Megalith API
  window.environmentName = appConfiguration.environmentName
  const sdk = new MegaportSDK(appConfiguration.publicApi, false, X_APP)
 
  // Integrations
  setupIntegrations(appConfiguration)

  // Init app
  const settings = {
    router,
    store,
    render: h => h(App),
    i18n,
    provide: runtimeProviders,
  }

  if (import.meta.env.VITE_USE_MOCK_API === 'true') {
    const { getWorker } = await import('./mocks/browser')
    const { onUnhandledRequest } = await import('./mocks/utils')

    const worker = getWorker(appConfiguration)

    worker.start({ onUnhandledRequest: onUnhandledRequest(appConfiguration) })

    // NOTE: Uncomment the following event listener to see all intercepted requests in the console
    // worker.events.on('request:start', ({ request }) => {
    //   console.log('Outgoing:', request.method, request.url)
    // })
  }

  window.mpApp = new Vue(settings).$mount('#mp')

  sdk.apiErrorCallback = err => {
    const pathWhiteList = ['/login', '/mfa', '/post-login']
    if (err.status === 401) {
      // Don't auto log out on these paths, let them handle it themselves.
      if (pathWhiteList.includes(window.mpApp.$route.path)) return false
      handleSessionTimeout()
      return true
    }
    return false
  }
})
