import { groupBy } from 'lodash'
import { CLOUD_ITEMS, FRANCEIX_UID, G_PRODUCT_TYPE_MEGAPORT, G_PRODUCT_TYPE_MVE } from '@/Globals.js'
import { cloneDeep } from 'lodash'
import sdk from '@megaport/api-sdk'

const enhanceLocations = (locations, rootState) => {
  return locations.map(location => {
    // Build up the enhancements as we go
    const enhancements = {
      serviceType: G_PRODUCT_TYPE_MEGAPORT, // All partner ports are by definition ports
      dc: {},
    }

    const foundLocation = rootState.Locations.locations.find(({ id }) => id === location.id)

    if (foundLocation) {
      const { formatted, dc, name, metro, address, country } = foundLocation

      enhancements.formattedLocation = formatted.long
      enhancements.dc = { ...dc, name }
      enhancements.metro = metro
      enhancements.city = address.city
      enhancements.country = country
    }

    return { ...location, ...enhancements }
  })
}

const initialState = {
  ready: false,
  cloudProviders: [],
  marketplaceProviders: [],
}
const getters = {
  /**
   * This will return a computed version of the CSPs with the ports enhanced with the full information
   * that is required to display in the UI.
   *
   * @param {object} state
   * @returns
   */
  enhancedCloudProviders: (state, _gettersArg, rootState) => {
    const cloudServiceProviders = cloneDeep(state.cloudProviders)

    return cloudServiceProviders.map(provider => ({ ...provider, locations: enhanceLocations(provider.locations, rootState) }))
  },
  enhancedMarketplaceProviders: (state, _gettersArg, rootState) => {
    const marketplaceProviders = cloneDeep(state.marketplaceProviders)

    return marketplaceProviders.map(provider => ({ ...provider, locations: enhanceLocations(provider.locations, rootState) }))
  },
}
const actions = {
  /**
   * Load the list of partner ports.
   *
   * @param {object} context
   */
  async loadPartnerData(context) {
    // Load partner ports
    const listOfPartnerPorts = await sdk.instance.lists('partnerPorts')
    // Something can be designated as a cloud company without having a special connect type. We want to pick them up
    // and recognise their companyUid explicitly
    // NOTE: This is currently always an empty array since there are no companies in CLOUD_ITEMS with connectType 'DEFAULT'
    const cloudCompanyUids = CLOUD_ITEMS.filter(item => item.connectType === 'DEFAULT').map(item => item.companyUids).flat()

    // Whitelist all the providers we know how to handle. This means that if someone adds a new connection type in the data
    // we will ignore it unless it's explicitly handled here. This will strip out the likes of MVE and IX.
    // NOTE: GCI does not feature in the list of CLOUD_ITEMS.
    // NOTE: SAP is in the list of CLOUD_ITEMS but not in this whitelist, which means it will always be filtered out.
    const whitelistedCloudProviders = ['AWS', 'AWSHC', 'GOOGLE', 'ALIBABA', 'AZURE', 'GCI', 'ORACLE', 'SFDC', 'AMSIX', 'NUTANIX', 'OUTSCALE', 'IBM']

    const cspPorts = listOfPartnerPorts.filter(port => {
      // Apply the filter that will remove the most first
      // NOTE: Currently the first operand will always return true as the cloudCompanyUids array is always empty
      if (!cloudCompanyUids.includes(port.companyUid) && !whitelistedCloudProviders.includes(port.connectType)) {
        return false
      }

      // Filter out any IBM 2.0 connections without IBM connectType
      // Usually DEFAULT connectType is filtered out above, but as IBM existed before being added as it's own connectType (2.0)
      // it doesn't get filtered out (due to the companyUid being re-used between 1.0 and 2.0).
      // So we have to add an additional check to filter out the old IBM CSP
      // NOTE: Currently this never happens as by this point the connectType is never 'DEFAULT'
      if (port.companyName === 'IBM Cloud' && port.connectType === 'DEFAULT') return false

      // Exclude any ports that can't be connected to
      return port.vxcPermitted
    })

    // Collect the aggregated speed for LAGs
    for (const port of cspPorts) {
      port._aggSpeed = port.speed
      if (port.aggregation_id && port.lag_primary) {
        const aggPorts = cspPorts.filter(p => p.aggregation_id === port.aggregation_id)
        port._aggSpeed = port.speed * aggPorts.length // All the ports must be the same speed
      }
    }

    // Strip out any non-primary lag ports
    const filteredCspPorts = cspPorts.filter(port => !(port.aggregation_id && !port.lag_primary))

    // We need to group the items with a specific connectType other than DEFAULT specified so that it collects all their ports even if it has a different
    // companyUid, and then group all the ones that are DEFAULT connectType by companyUid. Also needs to have separate items for the
    // different connect types under the same cloud provider, so include the connectType in the grouping
    const groupedCspPorts = groupBy(filteredCspPorts, port => {
      if (port.connectType === 'DEFAULT') {
        return `${port.companyUid}|${port.connectType}`
      }
      const csp = CLOUD_ITEMS.find(cloudItem => (Array.isArray(cloudItem.connectType) ? cloudItem.connectType.includes(port.connectType) : cloudItem.connectType === port.connectType))
      return `${csp.companyUids[0]}|${port.connectType}`
    })

    // Process for Marketplace Locations
    const filteredMarketplacePorts = listOfPartnerPorts.filter(port => {
      // Exclude hidden ports
      if (port.connectType === 'HIDE') return false

      // Exclude ports that are not permitted for VXC connections
      if (port.vxcPermitted === false) return false

      // For FranceIX marketplace connections, we intercept and change the connect type.
      // This is done because the connect type is sometimes set to 'DEFAULT' and sometimes set to 'FRANCEIX'.
      if (port.companyUid === FRANCEIX_UID && port.connectType === 'DEFAULT') {
        port.connectType = 'FRANCEIX'
      }

      if (!['DEFAULT', G_PRODUCT_TYPE_MVE, 'FRANCEIX'].includes(port.connectType)) return false

      return true
    })

    // Group ports by a composite key of company UID and connect type
    const groupedMarketplacePorts = groupBy(filteredMarketplacePorts, port => `${port.companyUid}|${port.connectType}`)

    // We will have one entry per company and connect type
    const processPortsByLocation = (groupedPorts, isCloud) => {
      return Object.keys(groupedPorts).map(key => {
        const [companyUid, connectType] = key.split('|')

        // We have the ports grouped by company and connect type. Now we want to group the ports for the CSP by location
        // so that we can collect the core information for the location
        const collectedLocations = []
        const byLocation = groupBy(groupedPorts[key], 'locationId')

        for (const locationId of Object.keys(byLocation)) {
          const maxVxcSpeed = Math.max(...byLocation[locationId].map(port => port.maxVxcSpeed), 0)
          const maxSpeed = Math.max(...byLocation[locationId].map(port => port.speed), 0)
          const maxAggSpeed = Math.max(...byLocation[locationId].map(port => port._aggSpeed), 0)

          // Add the location data to the collection
          collectedLocations.push({
            id: Number.parseInt(locationId),
            connectType: `${connectType}|${companyUid}`,
            rateLimit: maxSpeed,
            _aggSpeed: maxAggSpeed,
            maxVxcSpeed,
            productUid: byLocation[locationId].map(port => port.productUid)[0], // Used for IBM ports in getting max bandwidth
          })
        }

        let connectTypeInfo = ''

        if (isCloud) {
          // Where there is more than one connect type, we are now representing them separately, so need
          // to handle that scenario and add the connect type where required.
          const cloudItem = CLOUD_ITEMS.find(ci => ci.companyUids.includes(companyUid))

          if (Array.isArray(cloudItem.connectType)) {
            connectTypeInfo = connectType
          }
        }

        const companyImageBaseUrl = `${sdk.instance.baseurl}/v2/marketplace/logo`
        const companyImage = companyUid ? `${companyImageBaseUrl}/${companyUid}` : companyImageBaseUrl

        const uniqueId = isCloud ? `${companyUid}|${connectTypeInfo}` : companyUid

        return {
          companyUid,
          companyName: groupedPorts[key][0].companyName,
          portCount: groupedPorts[key].length, // This actually counts 1 for a LAG, which is probably what we want to do.
          ...isCloud ? { connectTypeInfo } : {},
          locations: collectedLocations,
          companyImage,
          uniqueId,
        }
      })
    }

    const processedCspArray = processPortsByLocation(groupedCspPorts, true)
    const marketplaceArray = processPortsByLocation(groupedMarketplacePorts, false)

    // Committing to state
    context.commit('setCloudProviders', processedCspArray)
    context.commit('setMarketplaceProviders', marketplaceArray)
    context.commit('setReady', true)
  },
}

const mutations = {
  setReady(state, tf) {
    state.ready = tf
  },
  setCloudProviders(state, cloudProviders) {
    state.cloudProviders = cloudProviders
  },
  setMarketplaceProviders(state, marketplaceProviders) {
    state.marketplaceProviders = marketplaceProviders
  },
}
export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
