import store from '@/store'

class GoogleLogin {
  /**
   * Use this to install the script at startup.
   */
  init() {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    script.onload = () => {
      store.commit('Auth/setGoogleSignInReady', true)
    }

    document.body.appendChild(script)
  }
}

export default new GoogleLogin()
