import sdk from '@megaport/api-sdk'
import { G_PRODUCT_TYPE_MEGAPORT } from '../../Globals'

const initialState = {
  ready: false,
  transitPorts: [],
  transitEnabledMarkets: [],
}

const getters = {
  /**
   * This will return a computed version of the transit ports
   * enhanced with the full information that is required to display in the UI.
   *
   * @param {object} state
   * @returns
   */
  enhancedTransitPorts: (state, _gettersArg, rootState) => {
    return state.transitPorts.map(transitPort => {
      // Get the location details for this port
      const { dc, market, metro, formatted } = rootState.Locations.locations.find(location => location.id === transitPort.id)
      const serviceType = G_PRODUCT_TYPE_MEGAPORT // All transit ports are by definition ports

      // Assign the extra port details
      return {
        ...transitPort,
        dc,
        formatted,
        market,
        metro,
        serviceType,
      }
    })
  },
}

const actions = {
  /**
   * Load the list of transit ports.
   *
   * @param {object} context
   */
  async loadTransitPorts(context) {
    const partnerPorts = await sdk.instance.lists('partnerPorts')

    const transitPorts = partnerPorts
      .filter(({ connectType }) => connectType === 'TRANSIT')
      .map(({ connectType, locationId, speed, title }) => {
        const rateLimit = Math.max(speed, 0)

        return {
          connectType,
          id: Number.parseInt(locationId),
          rateLimit,
          title,
          _aggSpeed: rateLimit, // Transit ports shouldn't ever be in a LAG (knock on wood)
        }
      })

    context.commit('setTransitPorts', transitPorts)
    context.commit('setReady', true)
  },

  async fetchTransitEnabledMarkets(context) {
    const { markets } = await sdk.instance.priceBook().transitMarkets({ distanceBand: 'METRO', aConnectTypes: ['DEFAULT', 'VROUTER'] })

    const uniqueMarkets = [...new Set(markets.map(({ countryCode }) => countryCode))]

    context.commit('setTransitEnabledMarkets', uniqueMarkets)
  },
}

const mutations = {
  setReady(state, ready) {
    state.ready = ready
  },

  setTransitPorts(state, transitPorts) {
    state.transitPorts = transitPorts
  },

  setTransitEnabledMarkets(state, payload) {
    state.transitEnabledMarkets = payload
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
